import { createAction, props } from '@ngrx/store';

export enum ExporterCustomerTypes {
    createExporterCustomer = '[ExporterCustomer] Create Exporter Customer',
    successCreateExporterCustomer = '[ExporterCustomer] Success Create ExporterCustomer',
    successAllExporterCustomer = '[ExporterCustomer] Success All ExporterCustomer',
    addExporterCustomers = '[ExporterCustomer] Add ExporterCustomers',
    getExporterCustomers = '[ExporterCustomer] Get ExporterCustomer',
    setUiExporterCustomer = '[ExporterCustomer] Set UI ExporterCustomer',
    selectedExporterCustomer = '[ExporterCustomer] Selected ExporterCustomer',
    updateExporterCustomer = '[ExporterCustomer] Update ExporterCustomer',
    removeAllExporterCustomer = '[ExporterCustomer] Remove all ExporterCustomers',
    searchExporterCustomer = '[ExporterCustomer] Search ExporterCustomers',
}

export const successExporterCustomer = createAction(ExporterCustomerTypes.successCreateExporterCustomer);
export const successAllExporterCustomer = createAction(ExporterCustomerTypes.successAllExporterCustomer);
export const removeAllExporterCustomer = createAction(ExporterCustomerTypes.removeAllExporterCustomer);
export const getExporterCustomers = createAction(ExporterCustomerTypes.getExporterCustomers);

export const AddExporterCustomers = createAction(
    ExporterCustomerTypes.addExporterCustomers,
    props<{ payload: Array<any> }>()
);

export const searchExporterCustomer = createAction(
    ExporterCustomerTypes.searchExporterCustomer,
    props<{ keyword: string }>()
);

export const setUiExporterCustomer = createAction(
    ExporterCustomerTypes.setUiExporterCustomer,
    props<{ payload: {  } }>()
);

export const createExporterCustomer = createAction(
    ExporterCustomerTypes.createExporterCustomer,
    props<{ payload: {  } }>()
);

export const updateExporterCustomer = createAction(
    ExporterCustomerTypes.updateExporterCustomer,
    props<{ payload: {  }, id: number }>()
);

export const selectedExporterCustomer = createAction(
    ExporterCustomerTypes.selectedExporterCustomer,
    props<{ id: number }>()
);
