import { ActionReducerMap } from '@ngrx/store';
import {AppState} from '@core/store/interfaces/app.interface';

import {exporterReducer} from '@modules/exporter/store/reducers/exporter.reducers';
import {measureReducer} from '@modules/measure/store/reducers/measure.reducers';
import {airlineReducer} from '@modules/airline/store/reducers/aeroline.reducers';
import {airportReducer} from '@modules/airport/store/reducers/airport.reducers';
import {comodityReducer} from '@modules/nature-cargo/store/reducers/comodity.reducers';
import {handlingInformationReducer} from '@modules/handling-information/store/reducers/handling-information.reducers';
import {messageReducer} from '@modules/message/store/reducers/message.reducers';
import {notificationReducer} from '@modules/notification/store/reducers/notification.reducers';
import {issuingCarrierReducer} from '@modules/issuing-carrier/store/reducers/issuing-carrier.reducers';
import {notifyReducer} from '@modules/notify/store/reducers/notify.reducers';
import {masterGuideReducer} from '@modules/guide-managenment/store/reducers/guide-managenment.reducers';
import {userReducer} from '@modules/user/store/reducers/user.reducer';
import {clientReducer} from '@modules/client/store/reducers/client.reducer';
import {exporterCustomerReducer} from '@modules/exporter-customer/store/reducers/exporter-customer.reducer';
import {complementationReducer} from '@modules/complementation/store/reducers/complementation.reducers';
import {itineraryReducer} from '@modules/itinerary/store/reducers/itinerary.reducers';
import {navieraReducer} from '@modules/naviera/store/reducers/naviera.reducers';
import { initAppReducer } from './store/reducers/init.reducer';
import { dashboardHomeReducer } from '@app/modules/dashboard-home/store/reducers/dashboardHome.reducers';

export const appReducers: ActionReducerMap< AppState | any > = {
    dashboardHome: dashboardHomeReducer,
    exporter: exporterReducer,
    measure: measureReducer,
    aeroline: airlineReducer,
    airport: airportReducer,
    natureCargo: comodityReducer,
    handlingInformation: handlingInformationReducer,
    issuingCarrier: issuingCarrierReducer,
    message: messageReducer,
    naviera: navieraReducer,
    notification: notificationReducer,
    notify: notifyReducer,
    masterGuide: masterGuideReducer,
    user: userReducer,
    client: clientReducer,
    exporterCustomer: exporterCustomerReducer,
    complementation: complementationReducer,
    itinerary: itineraryReducer,
    global: initAppReducer,
};
