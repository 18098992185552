import {createReducer, createSelector, on} from '@ngrx/store';
import * as aerolineActions from '../actions/aeroline.actions';
import { Aeroline } from '../../interfaces/aeroline.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {AppState} from '../../../../core/store/interfaces/app.interface';
import {IssuingCarrierState} from '../../../issuing-carrier/store/reducers/issuing-carrier.reducers';
import * as issuingCarrierActions from '../../../issuing-carrier/store/actions/issuing-carrier.actions';

export interface AerolineState extends EntityState<Aeroline> {
    selectedId: string;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
    ui: {
      visibleDrawer: boolean
    };
};


export const defaultAeroline: AerolineState = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
    error: '',
    ui: {
      visibleDrawer: false
    }
};


export const AerolineAdapter: EntityAdapter<Aeroline> = createEntityAdapter<Aeroline>();

export const AerolinesInitialState = AerolineAdapter.getInitialState(defaultAeroline);

const reducer = createReducer(AerolinesInitialState,
    on(aerolineActions.loadAerolinesSuccess, (state, { aerolines }) => (
        AerolineAdapter.setAll(aerolines, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(aerolineActions.loadAerolinesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(aerolineActions.loadAerolineSuccess, (state, { aeroline }) => (
        AerolineAdapter.addOne(aeroline, {
            ...state,
            selectedId: aeroline.id,
        })
    )),
    on(aerolineActions.loadAerolineError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(aerolineActions.createAerolineSuccess, (state, { aeroline }) => (
        AerolineAdapter.addOne(aeroline, state)
    )),
    on(aerolineActions.createAerolineError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(aerolineActions.updateAerolineSuccess, (state, { aeroline }) => (
        AerolineAdapter.updateOne(aeroline, state)
    )),
    on(aerolineActions.updateAerolineError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(aerolineActions.deleteAerolineSuccess, (state, { id }) => (
        AerolineAdapter.removeOne(id, state)
    )),
    on(aerolineActions.deleteAerolineError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(aerolineActions.loadAerolinesForCitySuccess, (state, { aerolines }) => (
        AerolineAdapter.setAll(aerolines, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(aerolineActions.loadAerolinesForCityError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(aerolineActions.selectedAirline, (state, { payload }) => ({ ...state, selectedId: payload })),
    on(aerolineActions.setUi, (state, { payload }) => ({ ...state, ui: { ...state.ui, ...payload } })),
);

export function airlineReducer(state, action): any {
    return reducer(state, action);
}

export const uiIAirline = ({ aeroline }: AppState) => aeroline.ui;
export const selectFeature = ({ aeroline }: AppState) => aeroline;

export const getAllAirline = createSelector(
  selectFeature,
  ( { ids, entities }: AerolineState) => ids.map(id => entities[id])
);

export const currentAirline = createSelector(
  selectFeature,
  ( { selectedId, entities }: AerolineState) => !entities[selectedId] ? null : entities[selectedId]);

