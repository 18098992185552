import { createReducer, createSelector, on } from '@ngrx/store';
import * as handlingInformationActions from '../actions/handling-information.actions';
import { handlingInformation } from '../../interfaces/handling-information.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { getSelectedhandlingInformation } from '../selectors/handling-information.selectors';
import { AppState } from 'src/app/core/store/interfaces/app.interface';

export interface handlingInformationState extends EntityState<handlingInformation> {
    ids: Array<any>,
    selectedhandlingInformationId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    ui: {
        visibleDrawer: boolean
    };
}

export const defaulthandlingInformation: handlingInformationState = {
    ids: [],
    entities: {},
    selectedhandlingInformationId: null,
    loading: false,
    loaded: false,
    error: '',
    ui: {
        visibleDrawer: false
    }
}

export const handlingInformationAdapter: EntityAdapter<handlingInformation> = createEntityAdapter<handlingInformation>();

export const handlingInformationsInitialState = handlingInformationAdapter.getInitialState(defaulthandlingInformation);

const reducer = createReducer(handlingInformationsInitialState,

    on(handlingInformationActions.removeAllhandlings, (state, { }) => handlingInformationAdapter.removeAll(state)),

    on(handlingInformationActions.loadhandlingInformationsSuccess, (state, { payload }) => (
        handlingInformationAdapter.setAll(payload, { ...state, loaded: true, loading: false })
    )),
    on(handlingInformationActions.loadhandlingInformationsError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(handlingInformationActions.loadhandlingInformationSuccess, (state, { handlingInformation }) => (
        handlingInformationAdapter.addOne(handlingInformation, {
            ...state,
            selectedhandlingInformationId: handlingInformation.id,
        })
    )),
    on(handlingInformationActions.loadhandlingInformationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(handlingInformationActions.createhandlingInformationSuccess, (state, { payload }) => (
        handlingInformationAdapter.addOne(payload, state)
    )),
    on(handlingInformationActions.createhandlingInformationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(handlingInformationActions.updatehandlingInformationSuccess, (state, { payload }) =>
        handlingInformationAdapter.updateOne(payload, state)),
    on(handlingInformationActions.updatehandlingInformationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(handlingInformationActions.deletehandlingInformationSuccess, (state, { id }) => (
        handlingInformationAdapter.removeOne(id, state)
    )),
    on(handlingInformationActions.deletehandlingInformationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(handlingInformationActions.setUi, (state, { payload }) => ({ ...state, ui: { ...state.ui, ...payload } })),
);

export function handlingInformationReducer(state, action) {
    return reducer(state, action);
}

export const uiIAirline = ({ aeroline }: AppState) => aeroline.ui;
export const selectFeature = ({ handlingInformation }: AppState) => handlingInformation;

export const getAllAirline = createSelector(
    selectFeature,
    ({ ids, entities }: handlingInformationState) => ids.map((id: any) => entities[id])
);

export const currentAirline = createSelector(
    selectFeature,
    ({ selectedhandlingInformationId, entities }: handlingInformationState) => !entities[selectedhandlingInformationId] ? null : entities[selectedhandlingInformationId]);

