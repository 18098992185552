import { createAction, props } from '@ngrx/store';
import { Notification } from '../../interfaces/notification.interface';

export enum NotificationActionsTypes {
    LoadNotifications = '[NOTIFICATION] Load Notifications',
    LoadNotificationsSuccess = '[NOTIFICATION] Load Notifications Success',
    LoadNotificationsError = '[NOTIFICATION] Load Notifications Error',
    LoadNotification = '[NOTIFICATION] Load Notification',
    LoadNotificationSuccess = '[NOTIFICATION] Load Notification Success',
    LoadNotificationError = '[NOTIFICATION] Load Notification Error'
}

export const loadNotifications = createAction(NotificationActionsTypes.LoadNotifications);

export const loadNotificationsSuccess = createAction(
    NotificationActionsTypes.LoadNotificationsSuccess,
    props<{ notifications: Notification[] }>()
);

export const loadNotificationsError = createAction(
    NotificationActionsTypes.LoadNotificationsError,
    props<{ payload: any }>()
);

export const loadNotification = createAction(
    NotificationActionsTypes.LoadNotification,
    props<{ id: string }>()
);

export const loadNotificationSuccess = createAction(
    NotificationActionsTypes.LoadNotificationSuccess,
    props<{ notification: Notification }>()
);

export const loadNotificationError = createAction(
    NotificationActionsTypes.LoadNotificationError,
    props<{ payload: any }>()
);