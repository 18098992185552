import {createReducer, createSelector, on} from '@ngrx/store';
import * as comodityActions from '../actions/nature-cargo.actions';
import { NatureCargo } from '../../interfaces/comodity.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {AppState} from '../../../../core/store/interfaces/app.interface';
import * as issuingCarrierActions from '../../../issuing-carrier/store/actions/issuing-carrier.actions';

export interface NatureCargoState extends EntityState<NatureCargo> {
  ids: Array<any>;
  selectedId: string;
    loading: boolean;
    loaded: boolean;
    error: string;
    ui: {
      visibleDrawer: boolean
    };
}

export const defaultNatureCargo: NatureCargoState = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
    error: '',
    ui: {
      visibleDrawer: false
    }
}

export const ComodityAdapter: EntityAdapter<NatureCargo> = createEntityAdapter<NatureCargo>();

export const ComoditysInitialState = ComodityAdapter.getInitialState(defaultNatureCargo);

const reducer = createReducer(ComoditysInitialState,
    on(comodityActions.loadNatureCargoSuccess, (state, { payload }) => (
        ComodityAdapter.setAll(payload, { ...state, loaded: true, loading: false })
    )),
    on(comodityActions.loadComoditiesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(comodityActions.loadComoditySuccess, (state, { comodity }) => (
        ComodityAdapter.addOne(comodity, {
            ...state,
            selectedComodityId: comodity.id,
        })
    )),
    on(comodityActions.loadComodityError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(comodityActions.createNatureCargoSuccess, (state, { payload }) => (
        ComodityAdapter.addOne(payload, state)
    )),
    on(comodityActions.createComodityError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(comodityActions.updateComoditySuccess, (state, { comodity }) => (
        ComodityAdapter.updateOne(comodity, state)
    )),
    on(comodityActions.updateComodityError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(comodityActions.deleteComoditySuccess, (state, { id }) => (
        ComodityAdapter.removeOne(id, state)
    )),
    on(comodityActions.deleteComodityError, (state, { payload }) => ({ ...state, error: payload })),
    on(comodityActions.setUi, (state, { payload }) => ({ ...state,  ui: { ...state.ui, ...payload } })),
    on(comodityActions.selectedNatureCargo, (state, { payload }) => ({ ...state, selectedId: payload })),
);

export function comodityReducer(state, action) {
    return reducer(state, action);
}

export const uiNatureCargo = ({ natureCargo }: AppState) => natureCargo.ui;
export const selectFeature = ({ natureCargo }: AppState) => natureCargo;

export const getAllNatureCargo = createSelector(
  selectFeature,
  ( { ids, entities }: NatureCargoState) => ids.map(id => entities[id])
);

export const currentNatureCargo = createSelector(
  selectFeature,
  ( { selectedId, entities }: NatureCargoState) => !entities[selectedId] ? null : entities[selectedId]);
