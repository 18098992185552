import { createAction, props } from '@ngrx/store';
import { ICreateUser, IUser } from '../../interfaces/user.interface';

export enum CustomerAppActionsTypes {
  createUser = '[User] Create user',
  updateUser = '[User] Update user',
  successCreateUser = '[User] Success Create user',
  successallUser = '[User] Success All user',
  addUsers = '[User] Add users',
  getUsers = '[User] Get User',
  setUiUser = '[User] Set UI User',
  clearUser = '[User] Clear User',
  clientAndExporter = '[User] ClientAndExporter',
}

export const successUser = createAction(
  CustomerAppActionsTypes.successCreateUser
);
export const successAllUser = createAction(
  CustomerAppActionsTypes.successallUser
);

export const clearUser = createAction(CustomerAppActionsTypes.clearUser);
export const getUsers = createAction(
  CustomerAppActionsTypes.getUsers,
  props<{ filter?: string }>()
);

export const clientAndExporter = createAction(
  CustomerAppActionsTypes.clientAndExporter
);

export const AddUsers = createAction(
  CustomerAppActionsTypes.addUsers,
  props<{ payload: Array<IUser> }>()
);

export const setUiUser = createAction(
  CustomerAppActionsTypes.setUiUser,
  props<{ payload: {} }>()
);

export const createUser = createAction(
  CustomerAppActionsTypes.createUser,
  props<ICreateUser>()
);

export const getClientAndExporter = createAction(
  CustomerAppActionsTypes.clientAndExporter,
  props<any>()
);

export const updateUser = createAction(
  CustomerAppActionsTypes.updateUser,
  props<{
    id: number;
    user: {
      name: string;
      last_name: string;
      rol_id: number;
      company_id: number;
      contact_number: string;
      role: string;
      email: string;
      status: boolean;
    };
  }>()
);
