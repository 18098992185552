import { Measure } from '../interfaces/measure.interface';
import { UNITS } from '../../exporter/mocks/units.mocks';

export const MEASURES: Array<Measure> = [
    {
        id: '1',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 21,
        length: 21,
        width: 21,
        isActive: true
    },
    {
        id: '2',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 41,
        length: 41,
        width: 41,
        isActive: false
    },
    {
        id: '3',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 61,
        length: 61,
        width: 61,
        isActive: null
    },
    {
        id: '4',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 22,
        length: 22,
        width: 22,
        isActive: true
    },
    {
        id: '5',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 42,
        length: 42,
        width: 42,
        isActive: false
    },
    {
        id: '6',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 62,
        length: 62,
        width: 62,
        isActive: null
    },
    {
        id: '7',
        unit: UNITS[0],
        date: new Date('2020-09-10'),
        height: 23,
        length: 23,
        width: 23,
        isActive: true
    }
];
