import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import {
  InitApp,
  loadAirlineApp,
  loadCountriesApp,
  saveAirlinesApp,
  saveCountriesApp,
} from '../actions/init.action';
import { CountryCoreService } from '../../services/countries/country-core.service';
import { AirlineGlobalServices } from '../../services/airlines/airline.services';

@Injectable()
export class IniEffects {
  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitApp.Start),
      exhaustMap(() =>
        of(true).pipe(
          mergeMap(() => [loadCountriesApp(), loadAirlineApp()]),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitApp.LoadCountries),
      exhaustMap(() =>
        this.countryCoreService.countries().pipe(
          map((countries) => saveCountriesApp(countries)),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadAirlines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitApp.LoadAirlines),
      exhaustMap(() =>
        this.airlineGlobalServices.getAllAirlineBase().pipe(
          map((airlines) => saveAirlinesApp({result: airlines})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private countryCoreService: CountryCoreService,
    private airlineGlobalServices: AirlineGlobalServices,
  ) {}
}
