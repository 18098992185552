import { createReducer, on } from '@ngrx/store';
import * as messageActions from '../actions/message.actions';
import { Message } from '../../interfaces/message.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface MessageState extends EntityState<Message> {
    selectedMessageId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultMessage: MessageState = {
    ids: [],
    entities: {},
    selectedMessageId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const messageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>();

export const messagesInitialState = messageAdapter.getInitialState(defaultMessage);

const reducer = createReducer(messagesInitialState,
    on(messageActions.loadMessagesSuccess, (state, { messages }) => (
        messageAdapter.setAll(messages, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(messageActions.loadMessagesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(messageActions.loadMessageSuccess, (state, { message }) => (
        messageAdapter.addOne(message, {
            ...state,
            selectedMessageId: message.id,
        })
    )),
    on(messageActions.loadMessageError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(messageActions.createMessageSuccess, (state, { message }) => (
        messageAdapter.addOne(message, state)
    )),
    on(messageActions.createMessageError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(messageActions.updateMessageSuccess, (state, { message }) => (
        messageAdapter.updateOne(message, state)
    )),
    on(messageActions.updateMessageError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(messageActions.deleteMessageSuccess, (state, { id }) => (
        messageAdapter.removeOne(id, state)
    )),
    on(messageActions.deleteMessageError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
);

export function messageReducer(state, action) {
    return reducer(state, action);
}