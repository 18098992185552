import { createAction, props } from '@ngrx/store';
import { Measure } from '../../interfaces/measure.interface';

export enum MeasureActionsTypes {
    LoadMeasures = '[MEASURES] Load Measures',
    LoadMeasuresSuccess = '[MEASURES] Load Measures Success',
    LoadMeasuresError = '[MEASURES] Load Measures Error',
    LoadMeasure = '[MEASURE] Load Measure',
    LoadMeasureSuccess = '[MEASURE] Load Measure Success',
    LoadMeasureError = '[MEASURE] Load Measure Error'
}

export const loadMeasures = createAction(MeasureActionsTypes.LoadMeasures);

export const loadMeasuresSuccess = createAction(
    MeasureActionsTypes.LoadMeasuresSuccess,
    props<{ measures: Measure[] }>()
);

export const loadMeasuresError = createAction(
    MeasureActionsTypes.LoadMeasuresError,
    props<{ payload: any }>()
);

export const loadMeasure = createAction(
    MeasureActionsTypes.LoadMeasure,
    props<{ id: string }>()
);

export const loadMeasureSuccess = createAction(
    MeasureActionsTypes.LoadMeasureSuccess,
    props<{ measure: Measure }>()
);

export const loadMeasureError = createAction(
    MeasureActionsTypes.LoadMeasureError,
    props<{ payload: any }>()
);