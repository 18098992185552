import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as exportActions from '../actions/exporter.actions';
import { ReservationService } from '../../services/reservation.service';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { Reservation } from '../../interfaces/reservation.interface';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({ providedIn: 'root' })
export class ExporterEffects {
  constructor(
    private actions$: Actions,
    private reservationService: ReservationService,
    private router: Router,
    private message: NzMessageService
  ) {}

  initReservations$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.initReservation),
      mergeMap(() => [exportActions.loadReservations({filter: ''})])
    )
  );

  loadUnitMeasure$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.loadUnitMeasureReservation),
      mergeMap(() =>
        this.reservationService
          .getMeasure()
          .pipe(
            mergeMap(({ units, measures }: any) => [
              exportActions.addUnits({ payload: units.reverse() }),
              exportActions.addMeasure({ payload: measures }),
            ])
          )
      )
    )
  );

  loadReservations$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.loadReservations),
      mergeMap(({filter}) =>
        this.reservationService.getReservations(filter ? filter : '').pipe(
          map((reservations: Reservation[]) =>
            exportActions.loadReservationsSuccess({ reservations })
          ),
          catchError((err) =>
            of(exportActions.loadReservationsError({ payload: err }))
          )
        )
      )
    )
  );

  addOneReservation$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.getReservation),
      mergeMap(({ id }) =>
        this.reservationService.getOneReservation(id).pipe(
          mergeMap((reservations: Reservation) => [
            exportActions.addOneReservation({ payload: reservations }),
            exportActions.selectedReservation({ id }),
          ]),
          catchError((err) =>
            of(exportActions.loadReservationsError({ payload: err }))
          )
        )
      )
    )
  );

  updateReservation$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.updateReservation),
      mergeMap(({ payload, id }) =>
        this.reservationService.updateReservation({ payload, id }).pipe(
          mergeMap((reservations: Reservation) => {
            this.router.navigate(['/app/exporter/']);
            return [exportActions.selectedReservation({ id: null })];
          }),
          catchError((err) =>
            of(exportActions.loadReservationsError({ payload: err }))
          )
        )
      )
    )
  );

  createReservation$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(exportActions.createReservation),
      mergeMap(({ payload }) =>
        this.reservationService.createReservation(payload).pipe(
          mergeMap((reservations: Reservation) => {
            this.router.navigate(['/app/exporter/']);
            return [exportActions.addOneReservation({ payload: reservations })];
          }),
          catchError((err) => {
            this.message.create('error', err.error.message, {nzDuration: 4000});
            return of(exportActions.loadReservationsError({ payload: err }));
          })
        )
      )
    )
  );
}
