import { createAction, props } from '@ngrx/store';
import { Reservation } from '../../interfaces/reservation.interface';
import { UpdateStr, Update } from '@ngrx/entity/src/models';

export enum ExporterActionsTypes {
    InitReservation = '[RESERVATIONS] Init Reservation',
    LoadReservations = '[RESERVATIONS] Load Reservations',
    LoadReservationsSuccess = '[RESERVATIONS] Load Reservations Success',
    LoadReservationsError = '[RESERVATIONS] Load Reservations Error',
    LoadReservation = '[RESERVATION] Load Reservation',
    LoadReservationSuccess = '[RESERVATION] Load Reservation Success',
    LoadReservationError = '[RESERVATION] Load Reservation Error',
    LoadReservationsToday = '[RESERVATIONS] Load Reservations Today',
    LoadReservationsSuccessToday = '[RESERVATIONS] Load Reservations Success Today',
    LoadReservationsErrorToday = '[RESERVATIONS] Load Reservations Error Today',
    UpdateReservationsToday = '[RESERVATIONS] Update Reservations Today',
    UpdateReservationsSuccessToday = '[RESERVATIONS] Update Reservations Success Today',
    UpdateReservationsErrorToday = '[RESERVATIONS] Update Reservations Error Today',
    SelectedReservation = '[RESERVATION] Selected Reservation',
    GetReservation = '[RESERVATION] Get Reservation',
    AddReservation = '[RESERVATION] Add One Reservation',
    CreateReservation = '[RESERVATION] Create Reservation',
    UpdateReservation = '[RESERVATION] Update Reservation',
    LoadUnitMeasure = '[RESERVATION] Unit and Measre',
    AddUnits = '[RESERVATION] Add Units',
    AddMeasures = '[RESERVATION] Add Measures',
    RemoveReservations = '[RESERVATION] Remove reservation',
}

export const initReservation = createAction(ExporterActionsTypes.InitReservation);
export const loadUnitMeasureReservation = createAction(ExporterActionsTypes.LoadUnitMeasure);
export const loadReservations = createAction(ExporterActionsTypes.LoadReservations,
  props<{ filter: any }>()
);
export const removeReservations = createAction(ExporterActionsTypes.RemoveReservations);

export const loadReservationsSuccess = createAction(
    ExporterActionsTypes.LoadReservationsSuccess,
    props<{ reservations: Reservation[] }>()
);

export const loadReservationsError = createAction(
    ExporterActionsTypes.LoadReservationsError,
    props<{ payload: any }>()
);

export const loadReservation = createAction(
    ExporterActionsTypes.LoadReservation,
    props<{ id: string }>()
);

export const loadReservationSuccess = createAction(
    ExporterActionsTypes.LoadReservationSuccess,
    props<{ reservation: Reservation }>()
);

export const loadReservationError = createAction(
    ExporterActionsTypes.LoadReservationError,
    props<{ payload: any }>()
);

export const loadReservationsToday = createAction(ExporterActionsTypes.LoadReservationsToday);

export const loadReservationsTodaySuccess = createAction(
    ExporterActionsTypes.LoadReservationsSuccess,
    props<{ reservations: Reservation[] }>()
);

export const loadReservationsTodayError = createAction(
    ExporterActionsTypes.LoadReservationsError,
    props<{ payload: any }>()
);

export const updateReservationsToday = createAction(
    ExporterActionsTypes.UpdateReservationsToday,
    props<{ reservations: Reservation[] }>()
);

export const updateReservationsTodaySuccess = createAction(
    ExporterActionsTypes.UpdateReservationsSuccessToday,
    props<{ reservations: Reservation[] }>()
);

export const updateReservationsTodayError = createAction(
    ExporterActionsTypes.UpdateReservationsErrorToday,
    props<{ payload: any }>()
);

// New

export const createReservation = createAction(
  ExporterActionsTypes.CreateReservation,
  props<{ payload: Reservation }>()
);

export const updateReservation = createAction(
  ExporterActionsTypes.UpdateReservation,
  props<{ payload: Reservation, id: number }>()
);

export const getReservation = createAction(
  ExporterActionsTypes.GetReservation,
  props<{ id: number }>()
);

export const selectedReservation = createAction(
  ExporterActionsTypes.SelectedReservation,
  props<{ id: number }>()
);

export const addOneReservation = createAction(
  ExporterActionsTypes.AddReservation,
  props<{ payload: Reservation }>()
);


// measure && unit

export const addUnits = createAction(
  ExporterActionsTypes.AddUnits,
  props<{ payload: any }>()
);

export const addMeasure = createAction(
  ExporterActionsTypes.AddMeasures,
  props<{ payload: any }>()
);
