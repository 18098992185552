import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CountryCoreService {

  constructor(private httpClient: HttpClient) { }

  public citiesCode(cityId: number): Observable<any> {
    return this.httpClient.get(`${env.gateway.managment}/cities/code/${cityId}`);
  }

  public countries(isActive: boolean = true): Observable<any> {
    return this.httpClient.get(`${env.gateway.managment}/countries?is_active=${isActive}`);
  }
}
