import { createAction, props } from '@ngrx/store';
import { Aeroline } from '../../interfaces/aeroline.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum AerolineActionsTypes {
  LoadAerolines = '[AEROLINES] Load Aerolines',
  LoadAerolinesSuccess = '[AEROLINES] Load Aerolines Success',
  LoadAerolinesError = '[AEROLINES] Load Aerolines Error',
  LoadAeroline = '[AEROLINES] Load Aeroline',
  LoadAerolineSuccess = '[AEROLINES] Load Aeroline Success',
  LoadAerolineError = '[AEROLINES] Load Aeroline Error',
  CreateAeroline = '[AEROLINES] Create Aeroline',
  CreateAerolineSuccess = '[AEROLINES] Create Aeroline Success',
  CreateAerolineError = '[AEROLINES] Create Aeroline Error',
  UpdateAeroline = '[AEROLINES] Update Aeroline',
  UpdateAerolineSuccess = '[AEROLINES] Update Aeroline Success',
  UpdateAerolineError = '[AEROLINES] Update Aeroline Error',
  DeleteAeroline = '[AEROLINES] Delete Aeroline',
  DeleteAerolineSuccess = '[AEROLINES] Delete Aeroline Success',
  DeleteAerolineError = '[AEROLINES] Delete Aeroline Error',
  LoadAerolinesForCity = '[AEROLINES] Load Aerolines For City',
  LoadAerolinesForCitySuccess = '[AEROLINES] Load Aerolines For City Success',
  LoadAerolinesForCityError = '[AEROLINES] Load Aerolines For City Error',
  SelectedAirline = '[AEROLINES] Selected airline',
  SetUi = '[AEROLINES] Set Ui',
}

export const loadAerolines = createAction(
  AerolineActionsTypes.LoadAerolines,
  props<{ id: number }>()
);

export const loadAerolinesSuccess = createAction(
  AerolineActionsTypes.LoadAerolinesSuccess,
  props<{ aerolines: Aeroline[] }>()
);

export const loadAerolinesError = createAction(
  AerolineActionsTypes.LoadAerolinesError,
  props<{ payload: any }>()
);

export const loadAeroline = createAction(
  AerolineActionsTypes.LoadAeroline,
  props<{ id: string }>()
);

export const loadAerolineSuccess = createAction(
  AerolineActionsTypes.LoadAerolineSuccess,
  props<{ aeroline: Aeroline }>()
);

export const loadAerolineError = createAction(
  AerolineActionsTypes.LoadAerolineError,
  props<{ payload: any }>()
);

export const createAeroline = createAction(
  AerolineActionsTypes.CreateAeroline,
  props<{ aeroline: Aeroline }>()
);

export const createAerolineSuccess = createAction(
  AerolineActionsTypes.CreateAerolineSuccess,
  props<{ aeroline: Aeroline }>()
);

export const createAerolineError = createAction(
  AerolineActionsTypes.CreateAerolineError,
  props<{ payload: any }>()
);

export const updateAeroline = createAction(
  AerolineActionsTypes.UpdateAeroline,
  props<{ aeroline: Aeroline }>()
);

export const updateAerolineSuccess = createAction(
  AerolineActionsTypes.UpdateAerolineSuccess,
  props<{ aeroline: UpdateStr<Aeroline> }>()
);

export const updateAerolineError = createAction(
  AerolineActionsTypes.UpdateAerolineError,
  props<{ payload: any }>()
);

export const deleteAeroline = createAction(
  AerolineActionsTypes.DeleteAeroline,
  props<{ id: string }>()
);

export const deleteAerolineSuccess = createAction(
  AerolineActionsTypes.DeleteAerolineSuccess,
  props<{ id: string }>()
);

export const deleteAerolineError = createAction(
  AerolineActionsTypes.DeleteAerolineError,
  props<{ payload: any }>()
);

export const loadAerolinesForCity = createAction(
  AerolineActionsTypes.LoadAerolinesForCity,
  props<{ city: string }>()
);

export const loadAerolinesForCitySuccess = createAction(
  AerolineActionsTypes.LoadAerolinesForCitySuccess,
  props<{ aerolines: Aeroline[] }>()
);

export const loadAerolinesForCityError = createAction(
  AerolineActionsTypes.LoadAerolinesForCityError,
  props<{ payload: any }>()
);

export const selectedAirline = createAction(
  AerolineActionsTypes.SelectedAirline,
  props<{ payload: any }>()
);

export const setUi = createAction(
  AerolineActionsTypes.SetUi,
  props<{ payload: any }>()
);
