import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  public isAuthenticated(): Observable<boolean> {
    try {
      const localToken = localStorage.getItem('token');
      const helper = new JwtHelperService();
      return !localToken ? of(false) : of(!helper.isTokenExpired(localToken));
    } catch (e) {
      return of(false);
    }
  }

  public isTokenExpired(): boolean {
    const localToken = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return !localToken ? false : !helper.isTokenExpired(localToken);
  }

  public getToken(): string {
    const localToken = localStorage.getItem('token');
    return localToken;
  }

  public getTokenOf(): Observable<string> {
    const localToken = localStorage.getItem('token');
    return of(localToken);
  }

  public removeToken(): void {
    localStorage.removeItem('token');
  }
}
