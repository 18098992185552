import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurchargeService } from '../services/surcharge.service';

@Injectable()
export class SurchargeModel {

    constructor(private surchargeService: SurchargeService) { }

    public getSurcharges(): Observable<any> {
        return this.surchargeService.getSurcharges();
    }

    public createSurcharges(data: any): Observable<any> {
        return this.surchargeService.createSurcharges(data);
    }

    public getHistorialSurcharge(data: any): Observable<any> {
        return this.surchargeService.getHistorialSurcharge(data);
    }

    public updateSurcharge(data: any): Observable<any> {
        return this.surchargeService.updateSurcharge(data);
    }

}
