import { createReducer, createSelector, on } from '@ngrx/store';
import * as issuingCarrierActions from '../actions/issuing-carrier.actions';
import { IssuingCarrier } from '../../interfaces/issuing-carrier.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '../../../../core/store/interfaces/app.interface';


export interface IssuingCarrierState extends EntityState<IssuingCarrier> {
    ids: any[];
    entities: {};
    selectedId: number;
    loading: boolean;
    loaded: boolean;
    error: string;
    ui: {
        visibleDrawer: boolean
    };
}

export const defaultIssuingCarrier: IssuingCarrierState = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
    error: '',
    ui: {
        visibleDrawer: false
    }
};

export const issuingCarrierAdapter: EntityAdapter<IssuingCarrier> = createEntityAdapter<IssuingCarrier>();

export const issuingCarriersInitialState = issuingCarrierAdapter.getInitialState(defaultIssuingCarrier);

const reducer = createReducer(issuingCarriersInitialState,

    on(issuingCarrierActions.removeAllIssuingCarriers, (state, { }) => issuingCarrierAdapter.removeAll(state)),

    on(issuingCarrierActions.loadIssuingCarriersSuccess, (state, { issuingCarriers }) => (
        issuingCarrierAdapter.setAll(issuingCarriers, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(issuingCarrierActions.loadIssuingCarriersError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(issuingCarrierActions.loadIssuingCarrierSuccess, (state, { issuingCarrier }) => (
        issuingCarrierAdapter.addOne(issuingCarrier, {
            ...state, selectedId: issuingCarrier.id,
        })
    )),
    on(issuingCarrierActions.loadIssuingCarrierError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(issuingCarrierActions.SetUiIssuingCarrier, (state, { payload }) => ({
        ...state,
        ui: { ...state.ui, ...payload }
    })),
    on(issuingCarrierActions.createIssuingCarrierSuccess, (state, { issuingCarrier }) => (
        issuingCarrierAdapter.addOne(issuingCarrier, state)
    )),
    on(issuingCarrierActions.createIssuingCarrierError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(issuingCarrierActions.updateIssuingCarrierSuccess, (state, { issuingCarrier }) => (
        issuingCarrierAdapter.updateOne(issuingCarrier, state)
    )),
    on(issuingCarrierActions.updateIssuingCarrierError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(issuingCarrierActions.deleteIssuingCarrierSuccess, (state, { id }) => (
        issuingCarrierAdapter.removeOne(id, state)
    )),
    on(issuingCarrierActions.deleteIssuingCarrierError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(issuingCarrierActions.selectedIssuingCarrier, (state, { payload }) => ({ ...state, selectedId: payload })),
);

export const issuingCarrierReducer = (state, action) => reducer(state, action);

export const uiIssuingCarrier = ({ issuingCarrier }: AppState) => issuingCarrier.ui;
export const selectFeature = ({ issuingCarrier }: AppState) => issuingCarrier;

export const getAllIssuingCarrier = createSelector(
    selectFeature,
    ({ ids, entities }: IssuingCarrierState) => ids.map(id => entities[id])
);

export const currentIssuingCarrier = createSelector(
    selectFeature,
    ({ selectedId, entities }: IssuingCarrierState) => !entities[selectedId] ? null : entities[selectedId]);

