import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any, format: string): any {
    return moment(value).add(1, 'days').format(format);
    // if (value) {
    //   const datePipe = new DatePipe('en-US');
    //   value = datePipe.transform(value, 'EEEE MMMM dd, yyyy');
    //   const daysAbbreviation = datePipe.transform(value, 'EEE');
    //   const monthAbbreviation = datePipe.transform(value, 'MMM');
    //   const day = datePipe.transform(value, 'dd');
    //   const year = datePipe.transform(value, 'yyyy');
    //   console.log(moment(value), moment(value).calendar(), moment(value).calendar())
    //   console.log(moment().format('Y-MM-ddd'))
    //   return {
    //     short: () => `${daysAbbreviation} ${monthAbbreviation} ${day}, ${year}`,
    //     long: () => `${monthAbbreviation} ${day}, ${year}`,
    //     custom: () =>
    //       `${daysAbbreviation}/${monthAbbreviation}/${day}/${year}`,
    //     customize: () =>  moment(value).format(format),
    //     default: () => value,
    //   }[format ?? 'default']();
    // }
    // return null;
  }
}
