import {Observable} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Reservation} from '@modules/exporter/interfaces/reservation.interface';


@Injectable({ providedIn: 'root' })
export class AirlineGlobalServices {

  constructor(private httpClient: HttpClient) { }

  public searchAirline(name: string): Observable<any> {
    return this.httpClient.get<Reservation[]>(`${env.gateway.managment}/airlines/search?name=${name}`);
  }

  public getItineraries(): Observable<any> {
    return this.httpClient.get(`${env.gateway.managment}/itinerary`);
  }

  public getAllAirlineBase(): Observable<any>{
    return this.httpClient.get(`${env.gateway.managment}/airlines/base`);
  }
}
