import { Unit } from '../../../core/interfaces/unit.interface';

export const UNITS: Array<Unit> = [
    {
        id: 1,
        date: new Date('2020-09-10'),
        type: 'Full',
        equivalency: 1
    },
    {
        id: 2,
        date: new Date('2020-09-10'),
        type: 'Half',
        equivalency: 1 / 2
    },
    {
        id: 3,
        date: new Date('2020-09-10'),
        type: 'Third',
        equivalency: 1 / 3
    },
    {
        id: 4,
        date: new Date('2020-09-10'),
        type: 'Quarter',
        equivalency: 1 / 4
    },
    {
        id: 5,
        date: new Date('2020-09-10'),
        type: 'Sixth',
        equivalency: 1 / 6
    },
    {
        id: 6,
        date: new Date('2020-09-10'),
        type: 'Eight',
        equivalency: 1 / 8
    },
    {
        id: 7,
        date: new Date('2020-09-10'),
        type: 'Sixteenth',
        equivalency: 1 / 16
    },
];
