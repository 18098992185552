export const surchargesAirline = [
    {
        surcharge_id: 1,
        surcharge: 'FS',
        value: 14
    },
    {
        surcharge_id: 2,
        surcharge: 'AWC',
        value: null
    },
    {
        surcharge_id: 3,
        surcharge: 'AWA',
        value: null
    },
    {
        surcharge_id: 4,
        surcharge: 'LC',
        value: null
    },
    {
        surcharge_id: 5,
        surcharge: 'Navidad',
        value: null
    },
]