import { createReducer, on } from '@ngrx/store';
import * as airportActions from '../actions/airport.actions';
import { Airport } from '../../interfaces/airport.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface AirportState extends EntityState<Airport> {
    selectedAirportId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultAirport: AirportState = {
    ids: [],
    entities: {},
    selectedAirportId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const airportAdapter: EntityAdapter<Airport> = createEntityAdapter<Airport>();

export const airportsInitialState = airportAdapter.getInitialState(defaultAirport);

const reducer = createReducer(airportsInitialState,
    on(airportActions.loadAirportsSuccess, (state, { airports }) => (
        airportAdapter.setAll(airports, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(airportActions.loadAirportsError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(airportActions.loadAirportSuccess, (state, { airport }) => (
        airportAdapter.addOne(airport, {
            ...state,
            selectedAirportId: airport.id,
        })
    )),
    on(airportActions.loadAirportError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(airportActions.createAirportSuccess, (state, { airport }) => (
        airportAdapter.addOne(airport, state)
    )),
    on(airportActions.createAirportError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(airportActions.updateAirportSuccess, (state, { airport }) => (
        airportAdapter.updateOne(airport, state)
    )),
    on(airportActions.updateAirportError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(airportActions.deleteAirportSuccess, (state, { id }) => (
        airportAdapter.removeOne(id, state)
    )),
    on(airportActions.deleteAirportError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
);

export function airportReducer(state, action) {
    return reducer(state, action);
}