import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as measureActions from '../actions/measure.actions';
import { mergeMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs';
import { MeasureService } from '../../services/measure.service';
import { Measure } from '../../interfaces/measure.interface';
import * as exportActions from '../../../exporter/store/actions/exporter.actions';

@Injectable({ providedIn: 'root' })
export class MeasureEffects {

    constructor(
        private actions$: Actions,
        private measureService: MeasureService
    ) { }

    // loadMeasures$ = createEffect(
    //     (): any => this.actions$.pipe(
    //         ofType(measureActions.MeasureActionsTypes.LoadMeasures),
    //         mergeMap(() => this.measureService.getMeasures()
    //             .pipe(
    //                 map((measures: Measure[]) => measureActions.loadMeasuresSuccess({ measures })),
    //                 catchError(err => of(measureActions.loadMeasuresError({ payload: err })))
    //             )
    //         )
    //     )
    // );

    loadMeasures$ = createEffect(
        (): any => this.actions$.pipe(
            ofType(measureActions.MeasureActionsTypes.LoadMeasures),
            mergeMap(() => this.measureService.getMeasures().pipe(
              mergeMap(({ units, measures }: any) => [
                exportActions.addUnits({ payload: units }),
                exportActions.addMeasure({ payload: measures }),
              ])
            ))
        )
    );

    loadMeasure$ = createEffect(
        (): any => this.actions$.pipe(
            ofType(measureActions.loadMeasure),
            mergeMap((action) => this.measureService.getMeasureById(action.id)
                .pipe(
                    map((measure: Measure) => measureActions.loadMeasureSuccess({ measure })),
                    catchError(err => of(measureActions.loadMeasureError({ payload: err })))
                )
            )
        )
    );
}
