import {createReducer, createSelector, on} from '@ngrx/store';
import * as exporterActions from '../actions/exporter.actions';
import { Reservation } from '../../interfaces/reservation.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '@core/store/interfaces/app.interface';

export interface ReservationState extends EntityState<Reservation> {
    selectedReservationId: number;
    loading: boolean;
    loaded: boolean;
    error: string;
    units: Array<any>;
    measure: Array<any>;
}

export const defaultReservation: ReservationState = {
    ids: [],
    entities: {},
    selectedReservationId: null,
    loading: false,
    loaded: false,
    error: '',
    units: [],
    measure: []
};

export const ReservationAdapter: EntityAdapter<Reservation> = createEntityAdapter<Reservation>();

export const ReservationsInitialState = ReservationAdapter.getInitialState(defaultReservation);

const reducer = createReducer(ReservationsInitialState,
    on(exporterActions.loadReservationsSuccess, (state, { reservations }) => (
        ReservationAdapter.setAll(reservations, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(exporterActions.loadReservationsError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(exporterActions.loadReservationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(exporterActions.loadReservationsTodaySuccess, (state, { reservations }) => (
        ReservationAdapter.setAll(reservations, {
            ...state,
            entities: {},
            loaded: true,
            loading: false,
        })
    )),
    on(exporterActions.loadReservationsTodayError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(exporterActions.updateReservationsTodaySuccess, (state, { reservations }) => (
        ReservationAdapter.setAll(reservations, state)
    )),
    on(exporterActions.updateReservationsTodayError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(exporterActions.selectedReservation, (state, { id }) => ({ ...state, selectedReservationId: id })),
    on(exporterActions.addOneReservation, (state, { payload }) => ReservationAdapter.addOne(payload, state)),
    on(exporterActions.addUnits, (state, { payload }) => ({ ...state, units: payload })),
    on(exporterActions.addMeasure, (state, { payload }) => ({ ...state, measure: payload })),
    on(exporterActions.removeReservations, (state ) => ReservationAdapter.removeAll(state)),
);


export function exporterReducer(state, action): any {
    return reducer(state, action);
}

export const selectFeature = (state: AppState) => state.exporter;

export const selectedeservation = createSelector(
  selectFeature,
  ( { selectedReservationId, entities }: ReservationState) =>
    !!entities[selectedReservationId] ? entities[selectedReservationId] : null
);
