import { createAction, props } from '@ngrx/store';
import { NatureCargo } from '../../interfaces/comodity.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum ComodityActionsTypes {
    LoadNatureCargo = '[COMODITY] Load Comodities',
    LoadComoditiesSuccess = '[COMODITY] Load ComoditiesSuccess',
    LoadComoditiesError = '[COMODITY] Load ComoditiesError',
    LoadComodity = '[COMODITY] Load Comodity',
    LoadComoditySuccess = '[COMODITY] Load Comodity Success',
    LoadComodityError = '[COMODITY] Load Comodity Error',
    CreateComodity = '[COMODITY] Create Comodity',
    CreateComoditySuccess = '[COMODITY] Create Comodity Success',
    CreateComodityError = '[COMODITY] Create Comodity Error',
    UpdateComodity = '[COMODITY] Update Comodity',
    UpdateComoditySuccess = '[COMODITY] Update Comodity Success',
    UpdateComodityError = '[COMODITY] Update Comodity Error',
    DeleteComodity = '[COMODITY] Delete Comodity',
    DeleteComoditySuccess = '[COMODITY] Delete Comodity Success',
    DeleteComodityError = '[COMODITY] Delete Comodity Error',
    SetUi = '[COMODITY] Set Ui',
    SelectedNatureCargo = '[COMODITY] Selected NatureCargo',
}

export const loadNatureCargo = createAction(ComodityActionsTypes.LoadNatureCargo);

export const loadNatureCargoSuccess = createAction(
    ComodityActionsTypes.LoadComoditiesSuccess,
    props<{ payload: NatureCargo[] }>()
);

export const loadComoditiesError = createAction(
    ComodityActionsTypes.LoadComoditiesError,
    props<{ payload: any }>()
);

export const loadComodity = createAction(
    ComodityActionsTypes.LoadComodity,
    props<{ id: string }>()
);

export const loadComoditySuccess = createAction(
    ComodityActionsTypes.LoadComoditySuccess,
    props<{ comodity: NatureCargo }>()
);

export const loadComodityError = createAction(
    ComodityActionsTypes.LoadComodityError,
    props<{ payload: any }>()
);

export const createNaturalCargo = createAction(
    ComodityActionsTypes.CreateComodity,
    props<{ payload: NatureCargo }>()
);

export const createNatureCargoSuccess = createAction(
    ComodityActionsTypes.CreateComoditySuccess,
    props<{ payload: NatureCargo }>()
);

export const createComodityError = createAction(
    ComodityActionsTypes.CreateComodityError,
    props<{ payload: any }>()
);

export const updateNatureCargo = createAction(
    ComodityActionsTypes.UpdateComodity,
    props<{ payload: NatureCargo }>()
);

export const updateComoditySuccess = createAction(
    ComodityActionsTypes.UpdateComoditySuccess,
    props<{ comodity: UpdateStr<NatureCargo> }>()
);

export const updateComodityError = createAction(
    ComodityActionsTypes.UpdateComodityError,
    props<{ payload: any }>()
);

export const deleteComodity = createAction(
    ComodityActionsTypes.DeleteComodity,
    props<{ id: string }>()
);

export const deleteComoditySuccess = createAction(
    ComodityActionsTypes.DeleteComoditySuccess,
    props<{ id: string }>()
);

export const deleteComodityError = createAction(
    ComodityActionsTypes.DeleteComodityError,
    props<{ payload: any }>()
);

export const setUi = createAction(
    ComodityActionsTypes.SetUi,
    props<{ payload: any }>()
);

export const selectedNatureCargo = createAction(
    ComodityActionsTypes.SelectedNatureCargo,
    props<{ payload: any }>()
);
