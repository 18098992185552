import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ParseDateUtil } from '@core/utils/parse-date';

@Pipe({
  name: 'customDateParse',
})
export class CustomDateParsePipe implements PipeTransform {
  transform(value: any, format: string): any {
    return moment(ParseDateUtil.parseDate(value)).format(format);
  }
}
