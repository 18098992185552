import { createReducer, createSelector, on } from '@ngrx/store';
import * as dashboardHomeActions from '../actions/dashboardHome.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '@core/store/interfaces/app.interface';
import { ChartData } from '@app/modules/chart/interfaces/chartData.interface';

export interface DashboardHomeState {
  loading: boolean;
  loaded: boolean;
  error: string;
  charts: {
    summary: Array<ChartData>,
    shipper: {};
    customer: {};
  };
}

export const defaultDashboardHome: DashboardHomeState = {
  loading: false,
  loaded: false,
  error: '',
  charts: {
    summary: [],
    shipper: {},
    customer: {},
  },
};

const reducer = createReducer(
  defaultDashboardHome,
  on(dashboardHomeActions.loadChartsSuccess, (state, { charts }) => ({
    ...state,
    loaded: true,
    loading: false,
    charts,
  })),
  on(dashboardHomeActions.loadChartsError, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload,
  }))
);

export function dashboardHomeReducer(state, action): any {
  return reducer(state, action);
}
