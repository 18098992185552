import { createAction, props } from '@ngrx/store';
import { Notify } from '../../interfaces/notify.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum NotifyActionsTypes {
    LoadNotifies = '[NOTIFY] Load Notifies',
    LoadNotifiesSuccess = '[NOTIFY] Load Notifies Success',
    LoadNotifiesError = '[NOTIFY] Load Notifies Error',
    LoadNotify = '[NOTIFY] Load Notify',
    LoadNotifySuccess = '[NOTIFY] Load Notify Success',
    LoadNotifyError = '[NOTIFY] Load Notify Error',
    CreateNotify = '[NOTIFY] Create Notify',
    CreateNotifySuccess = '[NOTIFY] Create Notify Success',
    CreateNotifyError = '[NOTIFY] Create Notify Error',
    UpdateNotify = '[NOTIFY] Update Notify',
    UpdateNotifySuccess = '[NOTIFY] Update Notify Success',
    UpdateNotifyError = '[NOTIFY] Update Notify Error',
    DeleteNotify = '[NOTIFY] Delete Notify',
    DeleteNotifySuccess = '[NOTIFY] Delete Notify Success',
    DeleteNotifyError = '[NOTIFY] Delete Notify Error',
    setUiNotify = '[NOTIFY] Set UI Notify',
}

export const loadNotifies = createAction(NotifyActionsTypes.LoadNotifies);

export const loadNotifiesSuccess = createAction(
    NotifyActionsTypes.LoadNotifiesSuccess,
    props<{ notifies: Notify[] }>()
);

export const loadNotifiesError = createAction(
    NotifyActionsTypes.LoadNotifiesError,
    props<{ payload: any }>()
);

export const loadNotify = createAction(
    NotifyActionsTypes.LoadNotify,
    props<{ id: string }>()
);

export const loadNotifySuccess = createAction(
    NotifyActionsTypes.LoadNotifySuccess,
    props<{ notify: Notify }>()
);

export const loadNotifyError = createAction(
    NotifyActionsTypes.LoadNotifyError,
    props<{ payload: any }>()
);

export const createNotify = createAction(
    NotifyActionsTypes.CreateNotify,
    props<{ notify: Notify }>()
);

export const createNotifySuccess = createAction(
    NotifyActionsTypes.CreateNotifySuccess,
    props<{ notify: Notify }>()
);

export const createNotifyError = createAction(
    NotifyActionsTypes.CreateNotifyError,
    props<{ payload: any }>()
);

export const updateNotify = createAction(
    NotifyActionsTypes.UpdateNotify,
    props<{ notify: Notify }>()
);

export const updateNotifySuccess = createAction(
    NotifyActionsTypes.UpdateNotifySuccess,
    props<{ notify: UpdateStr<Notify> }>()
);

export const updateNotifyError = createAction(
    NotifyActionsTypes.UpdateNotifyError,
    props<{ payload: any }>()
);

export const deleteNotify = createAction(
    NotifyActionsTypes.DeleteNotify,
    props<{ id: string }>()
);

export const deleteNotifySuccess = createAction(
    NotifyActionsTypes.DeleteNotifySuccess,
    props<{ id: string }>()
);

export const deleteNotifyError = createAction(
    NotifyActionsTypes.DeleteNotifyError,
    props<{ payload: any }>()
);

export const setUiNotify = createAction(
    NotifyActionsTypes.setUiNotify,
    props<{ payload: {} }>()
);