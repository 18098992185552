import { createAction, props } from '@ngrx/store';
import { MasterGuide } from '../../interfaces/masterGuide.interface';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Reservation } from 'src/app/modules/exporter/interfaces/reservation.interface';

export enum MasterGuideActionsTypes {
    LoadMasterGuides = '[MASTERGUIDE] Load MasterGuides',
    LoadMasterGuidesSuccess = '[MASTERGUIDE] Load MasterGuides Success',
    LoadMasterGuidesError = '[MASTERGUIDE] Load MasterGuides Error',
    LoadMasterGuide = '[MASTERGUIDE] Load MasterGuide',
    LoadMasterGuideSuccess = '[MASTERGUIDE] Load MasterGuide Success',
    LoadMasterGuideError = '[MASTERGUIDE] Load MasterGuide Error',
    CreateMasterGuide = '[MASTERGUIDE] Create MasterGuide',
    CreateMasterGuideSuccess = '[MASTERGUIDE] Create MasterGuide Success',
    CreateMasterGuideError = '[MASTERGUIDE] Create MasterGuide Error',
    UpdateMasterGuide = '[MASTERGUIDE] Update MasterGuide',
    UpdateMasterGuideSuccess = '[MASTERGUIDE] Update MasterGuide Success',
    UpdateMasterGuideError = '[MASTERGUIDE] Update MasterGuide Error',
    DeleteMasterGuide = '[MASTERGUIDE] Delete MasterGuide',
    DeleteMasterGuideSuccess = '[MASTERGUIDE] Delete MasterGuide Success',
    DeleteMasterGuideError = '[MASTERGUIDE] Delete MasterGuide Error'
}

export const loadMasterGuides = createAction(MasterGuideActionsTypes.LoadMasterGuides,
    props<{ filter: any }>()
);

export const loadMasterGuidesSuccess = createAction(
    MasterGuideActionsTypes.LoadMasterGuidesSuccess,
    props<{ masterGuides: MasterGuide[] }>()
);

export const loadMasterGuidesError = createAction(
    MasterGuideActionsTypes.LoadMasterGuidesError,
    props<{ payload: any }>()
);

export const loadMasterGuide = createAction(
    MasterGuideActionsTypes.LoadMasterGuide,
    props<{ id: string }>()
);

export const loadMasterGuideSuccess = createAction(
    MasterGuideActionsTypes.LoadMasterGuideSuccess,
    props<{ masterGuide: MasterGuide }>()
);

export const loadMasterGuideError = createAction(
    MasterGuideActionsTypes.LoadMasterGuideError,
    props<{ payload: any }>()
);

export const createMasterGuide = createAction(
    MasterGuideActionsTypes.CreateMasterGuide,
    props<{ masterGuide: MasterGuide }>()
);

export const createMasterGuideSuccess = createAction(
    MasterGuideActionsTypes.CreateMasterGuideSuccess,
    props<{ masterGuide: MasterGuide }>()
);

export const createMasterGuideError = createAction(
    MasterGuideActionsTypes.CreateMasterGuideError,
    props<{ payload: any }>()
);

export const updateMasterGuide = createAction(
    MasterGuideActionsTypes.UpdateMasterGuide,
    props<{ masterGuide: MasterGuide }>()
);

export const updateMasterGuideSuccess = createAction(
    MasterGuideActionsTypes.UpdateMasterGuideSuccess,
    props<{ masterGuide: UpdateStr<MasterGuide> }>()
);

export const updateMasterGuideError = createAction(
    MasterGuideActionsTypes.UpdateMasterGuideError,
    props<{ payload: any }>()
);

export const deleteMasterGuide = createAction(
    MasterGuideActionsTypes.DeleteMasterGuide,
    props<{ id: string }>()
);

export const deleteMasterGuideSuccess = createAction(
    MasterGuideActionsTypes.DeleteMasterGuideSuccess,
    props<{ id: string }>()
);

export const deleteMasterGuideError = createAction(
    MasterGuideActionsTypes.DeleteMasterGuideError,
    props<{ payload : any }>()
);
