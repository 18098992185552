import { ChartData } from '@app/modules/chart/interfaces/chartData.interface';
import { createAction, props } from '@ngrx/store';

export enum DashboardHomeActionsTypes {
  LoadCharts = '[DASHBOARD CHARTS] Load Charts',
  LoadChartsSuccess = '[DASHBOARD CHARTS] Load Charts Success',
  LoadChartsError = '[DASHBOARD CHARTS] Load Charts Error',
}

export const loadCharts = createAction(
  DashboardHomeActionsTypes.LoadCharts,
  props<{ city: string }>()
);

export const loadChartsSuccess = createAction(
  DashboardHomeActionsTypes.LoadChartsSuccess,
  props<{
    charts: {
      summary: Array<ChartData>;
      shipper: {};
      customer: {};
    };
  }>()
);

export const loadChartsError = createAction(
  DashboardHomeActionsTypes.LoadChartsError,
  props<{ payload: any }>()
);
