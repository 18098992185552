import { createAction, props } from '@ngrx/store';
import { IssuingCarrier } from '../../interfaces/issuing-carrier.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum IssuingCarrierActionsTypes {
    LoadIssuingCarriers = '[ISSUING CARRIER] Load IssuingCarriers',
    LoadIssuingCarriersSuccess = '[ISSUING CARRIER] Load IssuingCarriers Success',
    LoadIssuingCarriersError = '[ISSUING CARRIER] Load IssuingCarriers Error',
    LoadIssuingCarrier = '[ISSUING CARRIER] Load IssuingCarrier',
    LoadIssuingCarrierSuccess = '[ISSUING CARRIER] Load IssuingCarrier Success',
    LoadIssuingCarrierError = '[ISSUING CARRIER] Load IssuingCarrier Error',
    CreateIssuingCarrier = '[ISSUING CARRIER] Create IssuingCarrier',
    CreateIssuingCarrierSuccess = '[ISSUING CARRIER] Create IssuingCarrier Success',
    CreateIssuingCarrierError = '[ISSUING CARRIER] Create IssuingCarrier Error',
    UpdateIssuingCarrier = '[ISSUING CARRIER] Update IssuingCarrier',
    UpdateIssuingCarrierSuccess = '[ISSUING CARRIER] Update IssuingCarrier Success',
    UpdateIssuingCarrierError = '[ISSUING CARRIER] Update IssuingCarrier Error',
    DeleteIssuingCarrier = '[ISSUING CARRIER] Delete IssuingCarrier',
    DeleteIssuingCarrierSuccess = '[ISSUING CARRIER] Delete IssuingCarrier Success',
    DeleteIssuingCarrierError = '[ISSUING CARRIER] Delete IssuingCarrier Error',
    SetUiIssuingCarrier = '[ISSUING CARRIER] Set Ui Issuing Carrier',
    selectedIssuingCarrier = '[ISSUING CARRIER] Selected Issuing Carrier',
    RemoveAllIssuingCarrier = '[ISSUING CARRIERN] Remove all Issuing Carriers',
}

export const loadIssuingCarriers = createAction(IssuingCarrierActionsTypes.LoadIssuingCarriers);
export const removeAllIssuingCarriers = createAction(IssuingCarrierActionsTypes.RemoveAllIssuingCarrier);

export const selectedIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.selectedIssuingCarrier,
    props<{ payload: number }>()
);

export const loadIssuingCarriersSuccess = createAction(
    IssuingCarrierActionsTypes.LoadIssuingCarriersSuccess,
    props<{ issuingCarriers: IssuingCarrier[] }>()
);

export const loadIssuingCarriersError = createAction(
    IssuingCarrierActionsTypes.LoadIssuingCarriersError,
    props<{ payload: any }>()
);

export const SetUiIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.SetUiIssuingCarrier,
    props<{ payload: any }>()
);

export const loadIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.LoadIssuingCarrier,
    props<{ id: string }>()
);

export const loadIssuingCarrierSuccess = createAction(
    IssuingCarrierActionsTypes.LoadIssuingCarrierSuccess,
    props<{ issuingCarrier: IssuingCarrier }>()
);

export const loadIssuingCarrierError = createAction(
    IssuingCarrierActionsTypes.LoadIssuingCarrierError,
    props<{ payload: any }>()
);

export const createIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.CreateIssuingCarrier,
    props<{ issuingCarrier: IssuingCarrier }>()
);

export const createIssuingCarrierSuccess = createAction(
    IssuingCarrierActionsTypes.CreateIssuingCarrierSuccess,
    props<{ issuingCarrier: IssuingCarrier }>()
);

export const createIssuingCarrierError = createAction(
    IssuingCarrierActionsTypes.CreateIssuingCarrierError,
    props<{ payload: any }>()
);

export const updateIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.UpdateIssuingCarrier,
    props<{ issuingCarrier: IssuingCarrier }>()
);

export const updateIssuingCarrierSuccess = createAction(
    IssuingCarrierActionsTypes.UpdateIssuingCarrierSuccess,
    props<{ issuingCarrier: any }>()
);

export const updateIssuingCarrierError = createAction(
    IssuingCarrierActionsTypes.UpdateIssuingCarrierError,
    props<{ payload: any }>()
);

export const deleteIssuingCarrier = createAction(
    IssuingCarrierActionsTypes.DeleteIssuingCarrier,
    props<{ id: string }>()
);

export const deleteIssuingCarrierSuccess = createAction(
    IssuingCarrierActionsTypes.DeleteIssuingCarrierSuccess,
    props<{ id: string }>()
);

export const deleteIssuingCarrierError = createAction(
    IssuingCarrierActionsTypes.DeleteIssuingCarrierError,
    props<{ payload : any }>()
);
