import { createAction, props } from '@ngrx/store';

export const init = createAction('[INIT] Init');

export enum InitApp {
  Start = '[APP] init',
  LoadCountries = '[APP] loadCountry',
  SaveCountries = '[APP] SaveCountries',
  LoadAirlines = '[APP] loadAirline',
  SaveAirlines = '[APP] SaveAirline',
}

export const initApp = createAction(InitApp.Start);
export const loadCountriesApp = createAction(InitApp.LoadCountries);
export const saveCountriesApp = createAction(
  InitApp.SaveCountries,
  props<{ result: any[] }>()
);

export const loadAirlineApp = createAction(InitApp.LoadAirlines);
export const saveAirlinesApp = createAction(
  InitApp.SaveAirlines,
  props<{ result: any[] }>()
);
