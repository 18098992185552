import { UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { Message } from '../../interfaces/message.interface';

export enum MessageActionsTypes {
    LoadMessages = '[MESSAGE] Load Messages',
    LoadMessagesSuccess = '[MESSAGE] Load Messages Success',
    LoadMessagesError = '[MESSAGE] Load Messages Error',
    LoadMessage = '[MESSAGE] Load Message',
    LoadMessageSuccess = '[MESSAGE] Load Message Success',
    LoadMessageError = '[MESSAGE] Load Message Error',
    CreateMessage = '[MESSAGE] Create Message',
    CreateMessageSuccess = '[MESSAGE] Create Message Success',
    CreateMessageError = '[MESSAGE] Create Message Error',
    UpdateMessage = '[MESSAGE] Update Message',
    UpdateMessageSuccess = '[MESSAGE] Update Message Success',
    UpdateMessageError = '[MESSAGE] Update Message Error',
    DeleteMessage = '[MESSAGE] Delete Message',
    DeleteMessageSuccess = '[MESSAGE] Delete Message Success',
    DeleteMessageError = '[MESSAGE] Delete Message Error'
}

export const loadMessages = createAction(MessageActionsTypes.LoadMessages);

export const loadMessagesSuccess = createAction(
    MessageActionsTypes.LoadMessagesSuccess,
    props<{ messages: Message[] }>()
);

export const loadMessagesError = createAction(
    MessageActionsTypes.LoadMessagesError,
    props<{ payload: any }>()
);

export const loadMessage = createAction(
    MessageActionsTypes.LoadMessage,
    props<{ id: string }>()
);

export const loadMessageSuccess = createAction(
    MessageActionsTypes.LoadMessageSuccess,
    props<{ message: Message }>()
);

export const loadMessageError = createAction(
    MessageActionsTypes.LoadMessageError,
    props<{ payload: any }>()
);

export const createMessage = createAction(
    MessageActionsTypes.CreateMessage,
    props<{ message: Message }>()
);

export const createMessageSuccess = createAction(
    MessageActionsTypes.CreateMessageSuccess,
    props<{ message: Message }>()
);

export const createMessageError = createAction(
    MessageActionsTypes.CreateMessageError,
    props<{ payload: any }>()
);

export const updateMessage = createAction(
    MessageActionsTypes.UpdateMessage,
    props<{ message: Message }>()
);

export const updateMessageSuccess = createAction(
    MessageActionsTypes.UpdateMessageSuccess,
    props<{ message: UpdateStr<Message> }>()
);

export const updateMessageError = createAction(
    MessageActionsTypes.UpdateMessageError,
    props<{ payload: any }>()
);

export const deleteMessage = createAction(
    MessageActionsTypes.DeleteMessage,
    props<{ id: string }>()
);

export const deleteMessageSuccess = createAction(
    MessageActionsTypes.DeleteMessageSuccess,
    props<{ id: string }>()
);

export const deleteMessageError = createAction(
    MessageActionsTypes.DeleteMessageError,
    props<{ payload : any }>()
);