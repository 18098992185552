import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { AuthGuardService } from '../services/auth-guard.services';
import { AuthUser } from '@core/services/auth/authUser.services';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authGuardService: AuthGuardService,
    private authUser: AuthUser
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authGuardService.isAuthenticated().pipe(
      tap(() => this.startTokenCheck()),
      map((loggedIn: boolean) => {
        if (!loggedIn) {
          this.authGuardService.removeToken();
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }
        return loggedIn;
      })
    );
  }

  public startTokenCheck(): void {
    this.authUser.statusUser().subscribe(({ isValid }) => {
      if (!isValid) {
        this.authGuardService.removeToken();
        return this.router.navigate(['/login']);
      }

      const subscription = interval(300000)
        .pipe(switchMap(() => this.authUser.statusUser()))
        .subscribe(({ isValid }) => {
          if (!isValid) {
            this.authGuardService.removeToken();
            this.router.navigate(['/login']);
            subscription.unsubscribe();
          }
        });
    });
  }
}
