import { Injectable } from '@angular/core';
import { Measure } from '../interfaces/measure.interface';
import { of, Observable } from 'rxjs';
import { MEASURES } from '../mocks/measures.mocks';
import {environment as env} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {

  private measure: Measure;

  constructor(private httpClient: HttpClient) { }

  public getMeasures(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.gateway.managment}/measures`);
  }

  public createMeasure(measure): Observable<any[]> {
    return this.httpClient.post<any[]>(`${env.gateway.managment}/measure`, measure);
  }

  public getMeasureById(id: string): Observable<Measure> {
    // http
    MEASURES.forEach(measure => {
      if (id === measure.id) {
        this.measure = measure;
      }
    });
    return of(this.measure);
  }
}
