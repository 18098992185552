import { createAction, props } from '@ngrx/store';
import { handlingInformation } from '../../interfaces/handling-information.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum handlingInformationActionsTypes {
  LoadhandlingInformations = '[handling INFORMATION] Load handlingInformations',
  LoadhandlingInformationsSuccess = '[handling INFORMATION] Load handlingInformations Success',
  LoadhandlingInformationsError = '[handling INFORMATION] Load handlingInformations Error',
  LoadhandlingInformation = '[handling INFORMATION] Load handlingInformation',
  LoadhandlingInformationSuccess = '[handling INFORMATION] Load handlingInformation Success',
  LoadhandlingInformationError = '[handling INFORMATION] Load handlingInformation Error',
  CreatehandlingInformation = '[handling INFORMATION] Create handlingInformation',
  CreatehandlingInformationSuccess = '[handling INFORMATION] Create handlingInformation Success',
  CreatehandlingInformationError = '[handling INFORMATION] Create handlingInformation Error',
  UpdatehandlingInformation = '[handling INFORMATION] Update handlingInformation',
  UpdatehandlingInformationSuccess = '[handling INFORMATION] Update handlingInformation Success',
  UpdatehandlingInformationError = '[handling INFORMATION] Update handlingInformation Error',
  DeletehandlingInformation = '[handling INFORMATION] Delete handlingInformation',
  DeletehandlingInformationSuccess = '[handling INFORMATION] Delete handlingInformation Success',
  DeletehandlingInformationError = '[handling INFORMATION] Delete handlingInformation Error',
  SetUi = '[handling INFORMATION] Set Ui',
  RemoveAllhandlings = '[handling INFORMATION] Remove all handlings',
}

export const removeAllhandlings = createAction(handlingInformationActionsTypes.RemoveAllhandlings);

// Create
export const createhandlingInformation = createAction(
  handlingInformationActionsTypes.CreatehandlingInformation,
  props<{ payload: handlingInformation }>()
);

export const createhandlingInformationSuccess = createAction(
  handlingInformationActionsTypes.CreatehandlingInformationSuccess,
  props<{ payload: handlingInformation }>()
);

export const createhandlingInformationError = createAction(
  handlingInformationActionsTypes.CreatehandlingInformationError,
  props<{ payload: any }>()
);


// Update
export const updatehandlingInformation = createAction(
  handlingInformationActionsTypes.UpdatehandlingInformation,
  props<{ payload: any }>()
);

export const updatehandlingInformationSuccess = createAction(
  handlingInformationActionsTypes.UpdatehandlingInformationSuccess,
  props<{ payload: any }>()
);

export const updatehandlingInformationError = createAction(
  handlingInformationActionsTypes.UpdatehandlingInformationError,
  props<{ payload: any }>()
);



export const loadhandlingInformationsSuccess = createAction(
  handlingInformationActionsTypes.LoadhandlingInformationsSuccess,
  props<{ payload: Array<any> }>()
);

export const loadhandlingInformationsError = createAction(
  handlingInformationActionsTypes.LoadhandlingInformationsError,
  props<{ payload: any }>()
);

export const loadhandlingInformation = createAction(
  handlingInformationActionsTypes.LoadhandlingInformation,
  props<{ id: string }>()
);



export const loadhandlingInformations = createAction(handlingInformationActionsTypes.LoadhandlingInformations);



export const loadhandlingInformationSuccess = createAction(
  handlingInformationActionsTypes.LoadhandlingInformationSuccess,
  props<{ handlingInformation: handlingInformation }>()
);

export const loadhandlingInformationError = createAction(
  handlingInformationActionsTypes.LoadhandlingInformationError,
  props<{ payload: any }>()
);





export const deletehandlingInformation = createAction(
  handlingInformationActionsTypes.DeletehandlingInformation,
  props<{ id: string }>()
);

export const deletehandlingInformationSuccess = createAction(
  handlingInformationActionsTypes.DeletehandlingInformationSuccess,
  props<{ id: string }>()
);

export const deletehandlingInformationError = createAction(
  handlingInformationActionsTypes.DeletehandlingInformationError,
  props<{ payload: any }>()
);



export const setUi = createAction(
  handlingInformationActionsTypes.SetUi,
  props<{ payload: any }>()
);