import { Pipe, PipeTransform } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Pipe({ name: 'static' })
export class StaticMediaPipe implements PipeTransform {
  transform(key, path) {
    return key
      ? `${env.gateway.static}/${path}/${key}?timestamp=${new Date()
          .getTime()
          .toString(36)}`
      : '';
  }
}
