import { createAction, props } from '@ngrx/store';
import { Airport } from '../../interfaces/airport.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum AirportActionsTypes {
    LoadAirports = '[AIRPORTS] Load Airports',
    LoadAirportsSuccess = '[AIRPORTS] Load Airports Success',
    LoadAirportsError = '[AIRPORTS] Load Airports Error',
    LoadAirport = '[AIRPORTS] Load Airport',
    LoadAirportSuccess = '[AIRPORTS] Load Airport Success',
    LoadAirportError = '[AIRPORTS] Load Airport Error',
    CreateAirport = '[AIRPORTS] Create Airport',
    CreateAirportSuccess = '[AIRPORTS] Create Airport Success',
    CreateAirportError = '[AIRPORTS] Create Airport Error',
    UpdateAirport = '[AIRPORTS] Update Airport',
    UpdateAirportSuccess = '[AIRPORTS] Update Airport Success',
    UpdateAirportError = '[AIRPORTS] Update Airport Error',
    DeleteAirport = '[AIRPORTS] Delete Airport',
    DeleteAirportSuccess = '[AIRPORTS] Delete Airport Success',
    DeleteAirportError = '[AIRPORTS] Delete Airport Error'
}

export const loadAirports = createAction(AirportActionsTypes.LoadAirports);

export const loadAirportsSuccess = createAction(
    AirportActionsTypes.LoadAirportsSuccess,
    props<{ airports: Airport[] }>()
);

export const loadAirportsError = createAction(
    AirportActionsTypes.LoadAirportsError,
    props<{ payload: any }>()
);

export const loadAirport = createAction(
    AirportActionsTypes.LoadAirport,
    props<{ id: string }>()
);

export const loadAirportSuccess = createAction(
    AirportActionsTypes.LoadAirportSuccess,
    props<{ airport: Airport }>()
);

export const loadAirportError = createAction(
    AirportActionsTypes.LoadAirportError,
    props<{ payload: any }>()
);

export const createAirport = createAction(
    AirportActionsTypes.CreateAirport,
    props<{ airport: Airport }>()
);

export const createAirportSuccess = createAction(
    AirportActionsTypes.CreateAirportSuccess,
    props<{ airport: Airport }>()
);

export const createAirportError = createAction(
    AirportActionsTypes.CreateAirportError,
    props<{ payload: any }>()
);

export const updateAirport = createAction(
    AirportActionsTypes.UpdateAirport,
    props<{ airport: Airport }>()
);

export const updateAirportSuccess = createAction(
    AirportActionsTypes.UpdateAirportSuccess,
    props<{ airport: UpdateStr<Airport> }>()
);

export const updateAirportError = createAction(
    AirportActionsTypes.UpdateAirportError,
    props<{ payload: any }>()
);

export const deleteAirport = createAction(
    AirportActionsTypes.DeleteAirport,
    props<{ id: string }>()
);

export const deleteAirportSuccess = createAction(
    AirportActionsTypes.DeleteAirportSuccess,
    props<{ id: string }>()
);

export const deleteAirportError = createAction(
    AirportActionsTypes.DeleteAirportError,
    props<{ payload : any }>()
);