import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { surchargesAirline } from '../mocks/surcharges.mock';

@Injectable()
export class SurchargeService {

    constructor(private httpClient: HttpClient) { }

    public getSurcharges(): Observable<any> {
        //return this.httpClient.get('', data)
        return of(surchargesAirline)
    }

    public createSurcharges(data: any): Observable<any> {
        return this.httpClient.post('', data)
    }

    public getHistorialSurcharge(data: any): Observable<any> {
        return this.httpClient.get('', data)
    }

    public updateSurcharge(data: any): Observable<any> {
        return this.httpClient.put('', data)
    }

}
