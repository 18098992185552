import { createReducer, on } from '@ngrx/store';
import * as masterGuideActions from '../actions/guide-managenment.actions';
import { MasterGuide } from '../../interfaces/masterGuide.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface MasterGuideState extends EntityState<MasterGuide> {
    selectedMasterGuideId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultMasterGuide: MasterGuideState = {
    ids: [],
    entities: {},
    selectedMasterGuideId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const masterGuideAdapter: EntityAdapter<MasterGuide> = createEntityAdapter<MasterGuide>();

export const masterGuidesInitialState = masterGuideAdapter.getInitialState(defaultMasterGuide);

const reducer = createReducer(masterGuidesInitialState,
    on(masterGuideActions.loadMasterGuidesSuccess, (state, { masterGuides }) => (
        masterGuideAdapter.setAll(masterGuides, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(masterGuideActions.loadMasterGuidesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(masterGuideActions.loadMasterGuideSuccess, (state, { masterGuide }) => (
        masterGuideAdapter.addOne(masterGuide, {
            ...state,
            selectedMasterGuideId: masterGuide.id,
        })
    )),
    on(masterGuideActions.loadMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(masterGuideActions.createMasterGuideSuccess, (state, { masterGuide }) => (
        masterGuideAdapter.addOne(masterGuide, state)
    )),
    on(masterGuideActions.createMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(masterGuideActions.updateMasterGuideSuccess, (state, { masterGuide }) => (
        masterGuideAdapter.updateOne(masterGuide, state)
    )),
    on(masterGuideActions.updateMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(masterGuideActions.deleteMasterGuideSuccess, (state, { id }) => (
        masterGuideAdapter.removeOne(id, state)
    )),
    on(masterGuideActions.deleteMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

);

export function masterGuideReducer(state, action) {
    return reducer(state, action);
}