import { createAction, props } from '@ngrx/store';
import { Itinerary } from '../../interfaces/itinerary.interface';

export enum ItineraryActionsTypes {
    LoadItiniraries = '[ITINERARY] Load Itiniraries',
    LoadItinirariesSuccess = '[ITINERARY] Load Itiniraries Success',
    LoadItinirariesError = '[ITINERARY] Load Itiniraries Error',
    LoadItinerary = '[ITINERARY] Load Itinerary',
    LoadItinirarySuccess = '[ITINERARY] Load Itinerary Success',
    LoadItineraryError = '[ITINERARY] Load Itinerary Error',
    CreateItinerary = '[ITINERARY] Create Itinerary',
    CreateItinirarySuccess = '[ITINERARY] Create Itinerary Success',
    CreateItineraryError = '[ITINERARY] Create Itinerary Error',
    UpdateItinerary = '[ITINERARY] Update Itinerary',
    UpdateItinirarySuccess = '[ITINERARY] Update Itinerary Success',
    UpdateItineraryError = '[ITINERARY] Update Itinerary Error',
    SetUiItinerary = '[ITINERARY] Set Ui ITINERARY',
    selectedItinerary = '[ITINERARY] Selected ITINERARY',
    RemoveAllItinerary = '[ITINERARY] Remove all Itiniraries',

    LoadAirlinesItinerary = '[ITINERARY] Load Airlines Itinerary',
    LoadAirlinesItinerarySuccess = '[ITINERARY] Load Airlines Itinerary success',
    LoadAirlinesItineraryError = '[ITINERARY] Load Airlines Itinerary error',
    AddAirlineItinerary = '[ITINERARY] add Airline Itinerary',
    AddAirlineItinerarySuccess = '[ITINERARY] add Airline Itinerary success',
    AddAirlineItineraryError = '[ITINERARY] add Airline Itinerary error',
    RemoveAirlineItinerary = '[ITINERARY] remove Airline Itinerary',
    RemoveAirlineItinerarySuccess = '[ITINERARY] remove Airline Itinerary success',
    RemoveAirlineItineraryError = '[ITINERARY] remove Airline Itinerary error',

    CreateTariff = '[ITINERARY] Create Tariff',
    CreateTariffSuccess = '[ITINERARY] Create Tariff success',
    CreateTariffError = '[ITINERARY] Create Tariff error',
    UpdateTariff = '[ITINERARY] Update Tariff Itinerary',
    UpdateTariffSuccess = '[ITINERARY] Update Tariff Itinerary success',
    UpdateTariffError = '[ITINERARY] Update Tariff Itinerary error',
    LoadTariff = '[ITINERARY] Load Tariff Itinerary',
    LoadTariffSuccess = '[ITINERARY] Load Tariff Itinerary success',
    LoadTariffError = '[ITINERARY] Load Tariff Itinerary error',
    LoadHistorialTariffAirline = '[ITINERARY] Load Historial Itinerary',
    LoadHistorialTariffAirlineSuccess = '[ITINERARY] Load Historial Itinerary success',
    LoadHistorialTariffAirlineError = '[ITINERARY] Load Historial Itinerary error',

    CreateTariffClient = '[ITINERARY] Create Tariff Client',
    CreateTariffClientSuccess = '[ITINERARY] Create Tariff Client success',
    CreateTariffClientError = '[ITINERARY] Create Tariff Client error',
    UpdateTariffClient = '[ITINERARY] Update Tariff Client Itinerary',
    UpdateTariffClientSuccess = '[ITINERARY] Update Tariff Client Itinerary success',
    UpdateTariffClientError = '[ITINERARY] Update Tariff Client Itinerary error',
    LoadTariffClient = '[ITINERARY] Load Tariff Client Itinerary',
    LoadTariffClientSuccess = '[ITINERARY] Load Tariff Client Itinerary success',
    LoadTariffClientError = '[ITINERARY] Load Tariff Client Itinerary error',
}

export const loadItiniraries = createAction(ItineraryActionsTypes.LoadItiniraries);

export const removeAllItiniraries = createAction(ItineraryActionsTypes.RemoveAllItinerary);

export const SetUiItinerary = createAction(
    ItineraryActionsTypes.SetUiItinerary,
    props<{ payload: any }>()
);


export const selectedItinerary = createAction(
    ItineraryActionsTypes.selectedItinerary,
    props<{ payload: number }>()
);

export const loadItinirariesSuccess = createAction(
    ItineraryActionsTypes.LoadItinirariesSuccess,
    props<{ itiniraries: Itinerary[] }>()
);

export const loadItinirariesError = createAction(
    ItineraryActionsTypes.LoadItinirariesError,
    props<{ payload: any }>()
);


export const loadItinerary = createAction(
    ItineraryActionsTypes.LoadItinerary,
    props<{ id: number }>()
);

export const loadItinirarySuccess = createAction(
    ItineraryActionsTypes.LoadItinirarySuccess,
    props<{ itinerary: Itinerary }>()
);

export const loadItineraryError = createAction(
    ItineraryActionsTypes.LoadItineraryError,
    props<{ payload: any }>()
);


export const createItinerary = createAction(
    ItineraryActionsTypes.CreateItinerary,
    props<{ itinerary: Itinerary }>()
);

export const createItinirarySuccess = createAction(
    ItineraryActionsTypes.CreateItinirarySuccess,
    props<{ itinerary: Itinerary }>()
);

export const createItineraryError = createAction(
    ItineraryActionsTypes.CreateItineraryError,
    props<{ payload: any }>()
);


export const updateItinerary = createAction(
    ItineraryActionsTypes.UpdateItinerary,
    props<{ itinerary: Itinerary }>()
);

export const updateItinirarySuccess = createAction(
    ItineraryActionsTypes.UpdateItinirarySuccess,
    props<{ itinerary: any }>()
);

export const updateItineraryError = createAction(
    ItineraryActionsTypes.UpdateItineraryError,
    props<{ payload: any }>()
);


// ------------------- airlines ---------------------

export const loadAirlinesItinerary = createAction(
    ItineraryActionsTypes.LoadAirlinesItinerary,
    props<{ idItinerary: number }>()
);

export const loadAirlinesItinerarySuccess = createAction(
    ItineraryActionsTypes.LoadAirlinesItinerarySuccess,
    props<{ airlines: any }>()
);

export const loadAirlinesItineraryError = createAction(
    ItineraryActionsTypes.LoadAirlinesItineraryError,
    props<{ payload: any }>()
);


export const addAirlineItinerary = createAction(
    ItineraryActionsTypes.AddAirlineItinerary,
    props<{ body: any }>()
);

export const addAirlineItinerarySuccess = createAction(
    ItineraryActionsTypes.AddAirlineItinerarySuccess,
    props<{ airline: any }>()
);

export const addAirlineItineraryError = createAction(
    ItineraryActionsTypes.AddAirlineItineraryError,
    props<{ payload: any }>()
);


export const removeAirlineItinerary = createAction(
    ItineraryActionsTypes.RemoveAirlineItinerary,
    props<{ id: number }>()
);

export const removeAirlineItinerarySuccess = createAction(
    ItineraryActionsTypes.RemoveAirlineItinerarySuccess,
    props<{ payload: any }>()
);

export const removeAirlineItineraryError = createAction(
    ItineraryActionsTypes.RemoveAirlineItineraryError,
    props<{ payload: any }>()
);


// ----------------- HISTORIAL ------------------

export const createTariff = createAction(
    ItineraryActionsTypes.CreateTariff,
    props<{ tariff: any }>()
);

export const createTariffSuccess = createAction(
    ItineraryActionsTypes.CreateTariffSuccess,
    props<{ tariff: any }>()
);

export const createTariffError = createAction(
    ItineraryActionsTypes.CreateTariffError,
    props<{ payload: any }>()
);


export const updateTariff = createAction(
    ItineraryActionsTypes.UpdateTariff,
    props<{ tariff: any }>()
);

export const updateTariffSuccess = createAction(
    ItineraryActionsTypes.UpdateItinirarySuccess,
    props<{ tariff: any }>()
);

export const updateTariffError = createAction(
    ItineraryActionsTypes.UpdateTariffError,
    props<{ payload: any }>()
);

export const loadTariff = createAction(
    ItineraryActionsTypes.LoadTariff,
    props<{ idAirline: number }>()
);

export const loadTariffSuccess = createAction(
    ItineraryActionsTypes.LoadTariffSuccess,
    props<{ tariff: any }>()
);

export const loadTariffError = createAction(
    ItineraryActionsTypes.LoadTariffError,
    props<{ payload: any }>()
);

export const loadHistorialTariffAirline = createAction(
    ItineraryActionsTypes.LoadHistorialTariffAirline,
    props<{ idAirline: number }>()
);

export const loadHistorialTariffAirlineSuccess = createAction(
    ItineraryActionsTypes.LoadHistorialTariffAirlineSuccess,
    props<{ historial: Array<any> }>()
);

export const loadHistorialTariffAirlineError = createAction(
    ItineraryActionsTypes.LoadHistorialTariffAirlineError,
    props<{ payload: any }>()
);


// ------------- CLIENT -----------------

export const createTariffClient = createAction(
    ItineraryActionsTypes.CreateTariffClient,
    props<{ tariff: any }>()
);

export const createTariffClientSuccess = createAction(
    ItineraryActionsTypes.CreateTariffClientSuccess,
    props<{ tariff: any }>()
);

export const createTariffClientError = createAction(
    ItineraryActionsTypes.CreateTariffClientError,
    props<{ payload: any }>()
);


export const updateTariffClient = createAction(
    ItineraryActionsTypes.UpdateTariffClient,
    props<{ tariff: any }>()
);

export const updateTariffClientSuccess = createAction(
    ItineraryActionsTypes.UpdateItinirarySuccess,
    props<{ tariff: any }>()
);

export const updateTariffClientError = createAction(
    ItineraryActionsTypes.UpdateTariffClientError,
    props<{ payload: any }>()
);

export const loadTariffClient = createAction(
    ItineraryActionsTypes.LoadTariffClient,
    props<{ idAirline: number, idItinerary: number }>()
);

export const loadTariffClientSuccess = createAction(
    ItineraryActionsTypes.LoadTariffClientSuccess,
    props<{ tariff: any }>()
);

export const loadTariffClientError = createAction(
    ItineraryActionsTypes.LoadTariffClientError,
    props<{ payload: any }>()
);