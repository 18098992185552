import { createReducer, on } from '@ngrx/store';
import * as notificationActions from '../actions/notification.actions';
import { Notification } from '../../interfaces/notification.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface NotificationState extends EntityState<Notification> {
    selectedNotificationId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultNotification: NotificationState = {
    ids: [],
    entities: {},
    selectedNotificationId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const notificationAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>();

export const notificationsInitialState = notificationAdapter.getInitialState(defaultNotification);

const reducer = createReducer(notificationsInitialState,
    on(notificationActions.loadNotificationsSuccess, (state, { notifications }) => (
        notificationAdapter.setAll(notifications, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(notificationActions.loadNotificationsError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(notificationActions.loadNotificationSuccess, (state, { notification }) => (
        notificationAdapter.addOne(notification, {
            ...state,
            selectedNotificationId: notification.id,
        })
    )),
    on(notificationActions.loadNotificationError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
);

export function notificationReducer(state, action) {
    return reducer(state, action);
}