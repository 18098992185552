import { createReducer, createSelector, on } from '@ngrx/store';
import * as customerAppActions from '../actions/exporter-customer.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '../../../../core/store/interfaces/app.interface';

export interface ExporterCustomerState extends EntityState<any> {
  selectedId: number;
  ids: Array<any>;
  entities: { [id: number]: string };
  ui: {
    openModal: { status: boolean; type: string  },
    openDraweExporter: boolean
  };
}

export const defaultState: ExporterCustomerState = {
  ids: [],
  entities: {},
  selectedId: null,
  ui: {
    openModal: { status: false, type: null },
    openDraweExporter: false
  },
};

export const ExporterCustomerAdapter: EntityAdapter<any> = createEntityAdapter<any>();
export const anysInitialState = ExporterCustomerAdapter.getInitialState(defaultState);

const reducer = createReducer(anysInitialState,
  on(customerAppActions.AddExporterCustomers, (state, { payload }) => ExporterCustomerAdapter.setAll(payload, state) ),
  on(customerAppActions.removeAllExporterCustomer, (state, {  }) => ExporterCustomerAdapter.removeAll(state) ),
  on(customerAppActions.setUiExporterCustomer, (state, { payload }) => ({ ...state, ui: { ...state.ui, ...payload } })),
  on(customerAppActions.selectedExporterCustomer, (state, { id }) => ({ ...state, selectedId: id })),
);

export function exporterCustomerReducer(state, action): any {
  return reducer(state, action);
}

export const selectFeature = ({ exporterCustomer }: AppState) => exporterCustomer;
export const uiExporterCustomers = ({ exporterCustomer }: AppState) => exporterCustomer.ui;

export const getAllExporterCustomers = createSelector(
  selectFeature,
  ( { ids, entities }: ExporterCustomerState) => ids.map(id => entities[id])
);

export const currentexporterCustomer = createSelector(
  selectFeature,
  ( { selectedId, entities }: ExporterCustomerState) => !entities[selectedId] ? null : entities[selectedId]);


