import { createReducer, on } from '@ngrx/store';
import * as complementationActions from '../actions/complementation.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MasterGuide } from '../../../guide-managenment/interfaces/masterGuide.interface';
import {AppState} from '../../../../core/store/interfaces/app.interface';

export interface ComplementationState extends EntityState<MasterGuide> {
    selectedMasterGuideId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultMasterGuide: ComplementationState = {
    ids: [],
    entities: {},
    selectedMasterGuideId: null,
    loading: false,
    loaded: false,
    error: ''
}

export const masterGuideAdapter: EntityAdapter<MasterGuide> = createEntityAdapter<MasterGuide>();

export const masterGuidesInitialState = masterGuideAdapter.getInitialState(defaultMasterGuide);

const reducer = createReducer(masterGuidesInitialState,
    on(complementationActions.loadMasterGuidesSuccess, (state, { masterGuides }) => (
        masterGuideAdapter.setAll(masterGuides, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(complementationActions.loadMasterGuidesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(complementationActions.loadMasterGuideSuccess, (state, { masterGuide }) => (
        masterGuideAdapter.addOne(masterGuide, {
            ...state,
            selectedMasterGuideId: masterGuide.id,
        })
    )),
    on(complementationActions.loadMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(complementationActions.updateMasterGuideSuccess, (state, { masterGuide }) => (
        masterGuideAdapter.updateOne(masterGuide, state)
    )),
    on(complementationActions.updateMasterGuideError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
);

export function complementationReducer(state, action): any {
    return reducer(state, action);
}
