import {Observable} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';


@Injectable({ providedIn: 'root' })
export class AuthUser {

  constructor(private httpClient: HttpClient) { }

  public statusUser(): Observable<any> {
    return this.httpClient.get<any>(`${env.gateway.managment}/user/validate/status`);
  }

}
