import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as ItineraryActions from '../actions/itinerary.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '../../../../core/store/interfaces/app.interface';
import { airlines } from '../../mocks/airlines.mocks';
import { Itinerary } from '../../interfaces/itinerary.interface';


export interface ItineraryState  {
    ids: any[];
    entities: {};
    selectedId: number;
    loading: boolean;
    loaded: boolean;
    error: string;
    ui: {
        visibleDrawer: boolean,
        visibleDrawerAirline: boolean,
        visibleDrawerTariffAirline: boolean,
        visibleDrawerTariffClient: boolean
    };
    //TODO ONE OBJECT
    airlines: any[];
    historialTariff: any[];
    airlineTariff: any;
    clientTariff: any;
}

export const defaultItinerary: ItineraryState = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
    error: '',
    ui: {
        visibleDrawer: false,
        visibleDrawerAirline: false,
        visibleDrawerTariffAirline: false,
        visibleDrawerTariffClient: false
    },
    airlines: [],
    historialTariff: [],
    airlineTariff: null,
    clientTariff: null
}

export const ItineraryAdapter: EntityAdapter<Itinerary> = createEntityAdapter<Itinerary>();

export const ItinerarysInitialState = ItineraryAdapter.getInitialState(defaultItinerary);

const reducer = createReducer(ItinerarysInitialState,

    on(ItineraryActions.removeAllItiniraries, (state, { }) => ItineraryAdapter.removeAll(state)),

    on(ItineraryActions.loadItinirariesSuccess, (state, { itiniraries }) => (
        ItineraryAdapter.setAll(itiniraries, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(ItineraryActions.loadItinirariesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(ItineraryActions.loadItinirarySuccess, (state, { itinerary }) => (
        ItineraryAdapter.addOne(itinerary, {
            ...state, selectedId: itinerary.id,
        })
    )),
    on(ItineraryActions.loadItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.SetUiItinerary, (state, { payload }) => ({
        ...state,
        ui: { ...state.ui, ...payload }
    })),
    on(ItineraryActions.createItinirarySuccess, (state, { itinerary }) => (
        ItineraryAdapter.addOne(itinerary, state)
    )),
    on(ItineraryActions.createItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.updateItinirarySuccess, (state, { itinerary }) => (
        ItineraryAdapter.updateOne(itinerary, state)
    )),
    on(ItineraryActions.updateItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.selectedItinerary, (state, { payload }) => ({ ...state, selectedId: payload })),


    // ------------------ airlines -----------------

    on(ItineraryActions.loadAirlinesItinerarySuccess, (state, { airlines }) => (
        ItineraryAdapter.setAll(airlines, {
            ...state,
            loaded: true,
            loading: false,
            airlines
        })
    )),
    on(ItineraryActions.loadAirlinesItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

    on(ItineraryActions.addAirlineItinerarySuccess, (state, { airline }) => (
        ItineraryAdapter.addOne(airline, {
            ...state,
            entities: null,
            airlines: airline // TODO - corregir devolucion de peticion
        }) //TODO Otro adaptador
    )),
    on(ItineraryActions.addAirlineItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

    on(ItineraryActions.removeAirlineItinerarySuccess, (state, { payload }) => (
        ItineraryAdapter.removeOne(payload, {
            ...state,
            entities: null,
            airlines: payload
        })
    )),
    on(ItineraryActions.removeAirlineItineraryError, (state, { payload }) => ({
        ...state,
        error: payload
    })),


    on(ItineraryActions.loadHistorialTariffAirlineSuccess, (state, { historial }) => (
        ItineraryAdapter.setAll(historial, {
            ...state,
            loaded: true,
            loading: false,
            historialTariff: historial
        })
    )),
    on(ItineraryActions.loadHistorialTariffAirlineError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(ItineraryActions.loadTariffSuccess, (state, { tariff }) => (
        ItineraryAdapter.addOne(tariff, {
            ...state,
            entities: null,
            airlineTariff: tariff,
        })
    )),
    on(ItineraryActions.loadTariffError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.createTariffSuccess, (state, { tariff }) => (
        ItineraryAdapter.addOne(tariff, state)
    )),
    on(ItineraryActions.createTariffError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.updateTariffSuccess, (state, { tariff }) => (
        ItineraryAdapter.updateOne(tariff, state)
    )),
    on(ItineraryActions.updateTariffError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

    // ------------------- client ----------------------

    on(ItineraryActions.loadTariffClientSuccess, (state, { tariff }) => (
        ItineraryAdapter.addOne(tariff, {
            ...state,
            entities: null,
            clientTariff: tariff,
        })
    )),
    on(ItineraryActions.loadTariffClientError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.createTariffClientSuccess, (state, { tariff }) => (
        ItineraryAdapter.addOne(tariff, state)
    )),
    on(ItineraryActions.createTariffClientError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(ItineraryActions.updateTariffClientSuccess, (state, { tariff }) => (
        ItineraryAdapter.updateOne(tariff, state)
    )),
    on(ItineraryActions.updateTariffClientError, (state, { payload }) => ({
        ...state,
        error: payload
    })),


);

export function itineraryReducer(state, action): any {
    return reducer(state, action);
}

export const uiItinerary = ({ itinerary }: AppState | any) => itinerary.ui;
export const selectFeature = ({ itinerary }: AppState | any) => itinerary;

export const getAllItinerary = createSelector(
    selectFeature,
    ({ ids, entities }: ItineraryState) => ids.map(id => entities[id])
);

export const getAllAirlinesItinerary = createSelector(
    selectFeature,
    ({ airlines }: ItineraryState) => airlines);

export const currentItinerary = createSelector(
    selectFeature,
    ({ selectedId, entities }: ItineraryState) => !entities[selectedId] ? null : entities[selectedId]);

export const currentTariff = ({ itinerary }: AppState) => itinerary.airlineTariff;
export const currentTariffClient = ({ itinerary }: AppState) => itinerary.clientTariff;
export const historialTariff = ({ itinerary }: AppState) => itinerary.historialTariff;


