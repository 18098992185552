import { createReducer, createSelector, on } from '@ngrx/store';
import * as userActions from '../actions/user.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '../../../../core/store/interfaces/app.interface';

export interface UserState extends EntityState<any> {
  selectedId: number;
  ids: Array<any>;
  entities: { [id: number]: string };
  ui: {
    openModal: boolean
  };
}

export const defaultState: UserState = {
  ids: [],
  entities: {},
  selectedId: null,
  ui: {
    openModal: false
  },
};

export const UserAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const anysInitialState = UserAdapter.getInitialState(defaultState);

const reducer = createReducer(anysInitialState,
  on(userActions.AddUsers, (state, { payload }) => UserAdapter.setAll(payload, state)),
  on(userActions.setUiUser, (state, { payload }) => ({ ...state, ui: { ...state.ui, ...payload } })),
  on(userActions.clearUser, () => defaultState),
);

export function userReducer(state, action): any {
  return reducer(state, action);
}

export const selectFeature = ({ user }: AppState) => user;
export const UiUser = ({ user }: AppState) => user.ui;

export const getAllUsers = createSelector(selectFeature, ( { ids, entities }: UserState) => ids.map(id => entities[id]));

