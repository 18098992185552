import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateUtc'
})
export class DateUtcPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, format: string = 'medium'): string {
    const date = new Date(value);
    // date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return this.datePipe.transform(date, format);
  }
}
