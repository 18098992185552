import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CityService {

  constructor(private httpClient: HttpClient) { }

  public getAllCities(): Observable<any> {
    return this.httpClient.get(`${env.gateway.managment}/cities`);
  }
}
