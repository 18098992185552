import { createReducer, on } from '@ngrx/store';
import { init, loadCountriesApp, saveAirlinesApp, saveCountriesApp } from '../actions/init.action';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface DefaultGlobalState extends EntityState<any> {
  ids: any[];
  entities: {};
  countries: any[];
  airlines: any[];
}

export const defaultGlobalApp: any = {
  countries: [],
  cities: [],
  airlines: [],
}

export const appAdapter: EntityAdapter<any> = createEntityAdapter<any>();
export const initialAppState = appAdapter.getInitialState(defaultGlobalApp);

const reducer = createReducer(
  initialAppState,
  on(init, (state) => 'init app '),
  on(saveCountriesApp, (state, payload) => ({...state, countries: payload.result})),
  on(saveAirlinesApp, (state, payload) => ({...state, airlines: payload.result})),
);

export function initAppReducer(state, action): any {
  return reducer(state, action);
}
