import { createAction, props } from '@ngrx/store';
import { Naviera } from '../../interfaces/naviera.interface';
import { UpdateStr } from '@ngrx/entity/src/models';

export enum NavieraActionsTypes {
    LoadNavieras = '[NAVIERA] Load Navieras',
    LoadNavierasSuccess = '[NAVIERA] Load Navieras Success',
    LoadNavierasError = '[NAVIERA] Load Navieras Error',
    LoadNaviera = '[NAVIERA] Load Naviera',
    LoadNavieraSuccess = '[NAVIERA] Load Naviera Success',
    LoadNavieraError = '[NAVIERA] Load Naviera Error',
    CreateNaviera = '[NAVIERA] Create Naviera',
    CreateNavieraSuccess = '[NAVIERA] Create Naviera Success',
    CreateNavieraError = '[NAVIERA] Create Naviera Error',
    UpdateNaviera = '[NAVIERA] Update Naviera',
    UpdateNavieraSuccess = '[NAVIERA] Update Naviera Success',
    UpdateNavieraError = '[NAVIERA] Update Naviera Error',
    DeleteNaviera = '[NAVIERA] Delete Naviera',
    DeleteNavieraSuccess = '[NAVIERA] Delete Naviera Success',
    DeleteNavieraError = '[NAVIERA] Delete Naviera Error'
}

export const loadNavieras = createAction(NavieraActionsTypes.LoadNavieras);

export const loadNavierasSuccess = createAction(
    NavieraActionsTypes.LoadNavierasSuccess,
    props<{ navieras: Naviera[] }>()
);

export const loadNavierasError = createAction(
    NavieraActionsTypes.LoadNavierasError,
    props<{ payload: any }>()
);

export const loadNaviera = createAction(
    NavieraActionsTypes.LoadNaviera,
    props<{ id: string }>()
);

export const loadNavieraSuccess = createAction(
    NavieraActionsTypes.LoadNavieraSuccess,
    props<{ naviera: Naviera }>()
);

export const loadNavieraError = createAction(
    NavieraActionsTypes.LoadNavieraError,
    props<{ payload: any }>()
);

export const createNaviera = createAction(
    NavieraActionsTypes.CreateNaviera,
    props<{ naviera: Naviera }>()
);

export const createNavieraSuccess = createAction(
    NavieraActionsTypes.CreateNavieraSuccess,
    props<{ naviera: Naviera }>()
);

export const createNavieraError = createAction(
    NavieraActionsTypes.CreateNavieraError,
    props<{ payload: any }>()
);

export const updateNaviera = createAction(
    NavieraActionsTypes.UpdateNaviera,
    props<{ naviera: Naviera }>()
);

export const updateNavieraSuccess = createAction(
    NavieraActionsTypes.UpdateNavieraSuccess,
    props<{ naviera: UpdateStr<Naviera> }>()
);

export const updateNavieraError = createAction(
    NavieraActionsTypes.UpdateNavieraError,
    props<{ payload: any }>()
);

export const deleteNaviera = createAction(
    NavieraActionsTypes.DeleteNaviera,
    props<{ id: string }>()
);

export const deleteNavieraSuccess = createAction(
    NavieraActionsTypes.DeleteNavieraSuccess,
    props<{ id: string }>()
);

export const deleteNavieraError = createAction(
    NavieraActionsTypes.DeleteNavieraError,
    props<{ payload : any }>()
);    