import * as moment from 'moment';

export class ParseDateUtil {
  public static parseDate(date: any): any {
    const dateObject =
      date?.includes && date.includes('T')
        ? new Date(date)
        : new Date(
            new Date(date).getTime() -
              new Date(date).getTimezoneOffset() * 60000
          );
    return dateObject?.toISOString().split('T')[0];
  }

  public static parseCurrentDay(date, addDay = true): any {
    const currentDate = addDay ? moment(date).add(1, 'days') : moment(date);
    return currentDate.format('YYYY-MM-DD');
  }

  public static parseHour(date): any {
    const cuttentDate = new Date(date);
    return cuttentDate
      .toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })
      .replace(/\s[APap][Mm]$/, '');
  }

  public static restartHour(date): any {
    const [horas, minutos] = date?.split(':');

    const currentDate = new Date();
    currentDate.setHours(horas as any);
    currentDate.setMinutes(minutos as any);
    return currentDate;
  }

}
