import { createReducer, on } from '@ngrx/store';
import * as measureActions from '../actions/measure.actions';
import { Measure } from '../../interfaces/measure.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface MeasureState extends EntityState<Measure> {
    selectedMeasureId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultMeasure: MeasureState = {
    ids: [],
    entities: {},
    selectedMeasureId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const MeasureAdapter: EntityAdapter<Measure> = createEntityAdapter<Measure>();

export const MeasuresInitialState = MeasureAdapter.getInitialState(defaultMeasure);

const reducer = createReducer(MeasuresInitialState,
    on(measureActions.loadMeasuresSuccess, (state, { measures }) => (
        MeasureAdapter.setAll(measures, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(measureActions.loadMeasuresError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(measureActions.loadMeasureSuccess, (state, { measure }) => (
        MeasureAdapter.addOne(measure, {
            ...state,
            selectedMeasureId: measure.id,
        })
    )),
    on(measureActions.loadMeasureError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
);

export function measureReducer(state, action) {
    return reducer(state, action);
}