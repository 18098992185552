import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './app.reducers';
import { localEffects } from './app.effects';
import { CityService } from './services/cities/city.service';
import { SurchargeService } from './services/surcharge.service';
import { SurchargeModel } from './models/surcharge.model';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(localEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    CityService,
    SurchargeModel,
    SurchargeService
  ]
})
export class CoreModule { }
