import { createReducer, on } from '@ngrx/store';
import * as notifyActions from '../actions/notify.actions';
import { Notify } from '../../interfaces/notify.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface NotifyState extends EntityState<Notify> {
    selectedNotifyId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultNotify: NotifyState = {
    ids: [],
    entities: {},
    selectedNotifyId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const notifyAdapter: EntityAdapter<Notify> = createEntityAdapter<Notify>();

export const notifysInitialState = notifyAdapter.getInitialState(defaultNotify);

const reducer = createReducer(notifysInitialState,
    on(notifyActions.loadNotifiesSuccess, (state, { notifies }) => (
        notifyAdapter.setAll(notifies, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(notifyActions.loadNotifiesError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(notifyActions.loadNotifySuccess, (state, { notify }) => (
        notifyAdapter.addOne(notify, {
            ...state,
            selectedNotifyId: notify.id,
        })
    )),
    on(notifyActions.loadNotifyError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(notifyActions.createNotifySuccess, (state, { notify }) => (
        notifyAdapter.addOne(notify, state)
    )),
    on(notifyActions.createNotifyError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(notifyActions.updateNotifySuccess, (state, { notify }) => (
        notifyAdapter.updateOne(notify, state)
    )),
    on(notifyActions.updateNotifyError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(notifyActions.deleteNotifySuccess, (state, { id }) => (
        notifyAdapter.removeOne(id, state)
    )),
    on(notifyActions.deleteNotifyError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

);

export function notifyReducer(state, action) {
    return reducer(state, action);
}