import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateUtcPipe } from '../../core/pipes/data-utc.pipe';
import { CustomDatePipe } from '../../core/pipes/custom-date.pipe';
import { StaticMediaPipe } from '../../core/pipes/static-media.pipe';
import { CustomDateParsePipe } from '@core/pipes/custom-date-parse.pipe';

@NgModule({
  declarations: [DateUtcPipe, CustomDatePipe, StaticMediaPipe, CustomDateParsePipe],
  exports: [DateUtcPipe, CustomDatePipe, StaticMediaPipe, CustomDateParsePipe],
  providers: [DatePipe, CustomDatePipe, StaticMediaPipe, CustomDateParsePipe],
})
export class GlobalPipeModule {}
