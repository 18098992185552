import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div>
      <nz-result
        nzStatus="404"
        nzTitle="404"
        nzSubTitle="Lo sentimos, la página que has visitado no existe."
      >
        <div nz-result-extra>
          <button class="km-btn" style="margin: 11px 0" routerLink="/app">
            Regresar a la app
          </button>
        </div>
      </nz-result>
    </div>
  `,
  styles: [``],
})
export class NotFoundComponent {}
