import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reservation } from '../interfaces/reservation.interface';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ReservationService {

  constructor(private httpClient: HttpClient) { }

  public getReservations(params: string): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(`${env.gateway.managment}/reservations${params}`);
  }

  public getOneReservation(id: number): Observable<Reservation> {
    return this.httpClient.get<Reservation>(`${env.gateway.managment}/reservation/${id}`);
  }

  public updateReservation({ payload, id }): Observable<Reservation> {
    return this.httpClient.put<Reservation>(`${env.gateway.managment}/reservations/${id}`, payload);
  }

  public createReservation(reservation: Reservation): Observable<any> {
    return this.httpClient.post(`${env.gateway.managment}/reservation`, reservation);
  }

  public deleteReserva(id: number): Observable<any> {
    return this.httpClient.delete(`${env.gateway.managment}/reservation/${id}`);
  }

  public getMeasure(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.gateway.managment}/measures`);
  }

  public getUnits(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.gateway.managment}/unit`);
  }

  public getclients(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.gateway.managment}/exporter/client/${id}`);
  }

  public getclient(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.gateway.managment}/clients/${id}`);
  }

  public uploadInvoices(invoices: FormData, reservationId): Observable<any> {
    return this.httpClient
      .post(`${env.gateway.managment}/media/documents/upload?id=${reservationId}&type=consolidated`, invoices);
  }

  public deleteInvoices({ id, type }, file): Observable<any> {
    return this.httpClient
      .delete(`${env.gateway.managment}/media/documents/?id=${id}&type=${type}&key=${file}`);
  }

  public allConfigurationDay(): Observable<any> {
    return this.httpClient.get(`${env.gateway.managment}/global/days-disabled`);
  }
}
