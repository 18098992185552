export const environment = {
  production: false,
  gateway: {
    baseUrl: 'https://dev-ms.allfreightint.com',
    socketIO: 'https://dev-ms.allfreightint.com',
    managment: 'https://dev-ms.allfreightint.com/managment',
    account: 'https://dev-ms.allfreightint.com/account',
    static: 'https://km-static.sfo3.digitaloceanspaces.com',
  }
};
