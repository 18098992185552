import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/interfaces/app.interface';
import { initApp } from './core/store/actions/init.action';

@Component({
  selector: 'app-root',
  template: `
    <div>
      <div class="full-loading" *ngIf="isLoading">
        <div class="center-roller">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <p class="typography-description text-center m-5p">
              Loading app...
            </p>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [],
})
export class AppComponent implements OnInit {
  public isLoading = true;
  constructor(private store: Store<AppState>) {}
  ngOnInit(): void {
    this.store.dispatch(initApp());
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      this.isLoading = false;
      document.body.style.overflow = 'auto';
    }, 2000);
  }
}
