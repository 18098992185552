import { createAction, props } from '@ngrx/store';
import { UpdateStr } from '@ngrx/entity/src/models';
import { MasterGuide } from '../../../guide-managenment/interfaces/masterGuide.interface';

export enum ComplementationActionsTypes {
  LoadMasterGuides = '[MASTERGUIDE] Load MasterGuides',
  LoadMasterGuidesSuccess = '[MASTERGUIDE] Load MasterGuides Success',
  LoadMasterGuidesError = '[MASTERGUIDE] Load MasterGuides Error',
  LoadMasterGuide = '[MASTERGUIDE] Load MasterGuide',
  LoadMasterGuideSuccess = '[MASTERGUIDE] Load MasterGuide Success',
  LoadMasterGuideError = '[MASTERGUIDE] Load MasterGuide Error',
  UpdateMasterGuide = '[MASTERGUIDE] Update MasterGuide',
  UpdateMasterGuideSuccess = '[MASTERGUIDE] Update MasterGuide Success',
  UpdateMasterGuideError = '[MASTERGUIDE] Update MasterGuide Error',
  UpdateMasterGuideErrors = '[MASTERGUIDE] Update MasterGuide Error',
}

export const loadMasterGuides = createAction(
  ComplementationActionsTypes.LoadMasterGuides,
  props<{ filter?: any }>()
);

export const loadMasterGuidesSuccess = createAction(
  ComplementationActionsTypes.LoadMasterGuidesSuccess,
  props<{ masterGuides: MasterGuide[] }>()
);

export const loadMasterGuidesError = createAction(
  ComplementationActionsTypes.LoadMasterGuidesError,
  props<{ payload: any }>()
);

export const loadMasterGuide = createAction(
  ComplementationActionsTypes.LoadMasterGuide,
  props<{ id: string }>()
);

export const loadMasterGuideSuccess = createAction(
  ComplementationActionsTypes.LoadMasterGuideSuccess,
  props<{ masterGuide: MasterGuide }>()
);

export const loadMasterGuideError = createAction(
  ComplementationActionsTypes.LoadMasterGuideError,
  props<{ payload: any }>()
);

export const updateMasterGuide = createAction(
  ComplementationActionsTypes.UpdateMasterGuide,
  props<{ payload: any; id: number }>()
);

export const updateMasterGuideSuccess = createAction(
  ComplementationActionsTypes.UpdateMasterGuideSuccess,
  props<{ masterGuide: UpdateStr<MasterGuide> }>()
);

export const updateMasterGuideError = createAction(
  ComplementationActionsTypes.UpdateMasterGuideError,
  props<{ payload: any }>()
);
