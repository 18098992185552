import { createReducer, on } from '@ngrx/store';
import * as navieraActions from '../actions/naviera.actions';
import { Naviera } from '../../interfaces/naviera.interface';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface NavieraState extends EntityState<Naviera> {
    selectedNavieraId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultNaviera: NavieraState = {
    ids: [],
    entities: {},
    selectedNavieraId: null,
    loading: false,
    loaded: false,
    error: ""
}

export const navieraAdapter: EntityAdapter<Naviera> = createEntityAdapter<Naviera>();

export const navierasInitialState = navieraAdapter.getInitialState(defaultNaviera);

const reducer = createReducer(navierasInitialState,
    on(navieraActions.loadNavierasSuccess, (state, { navieras }) => (
        navieraAdapter.setAll(navieras, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(navieraActions.loadNavierasError, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(navieraActions.loadNavieraSuccess, (state, { naviera }) => (
        navieraAdapter.addOne(naviera, {
            ...state,
            selectedNavieraId: naviera.id,
        })
    )),
    on(navieraActions.loadNavieraError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(navieraActions.createNavieraSuccess, (state, { naviera }) => (
        navieraAdapter.addOne(naviera, state)
    )),
    on(navieraActions.createNavieraError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(navieraActions.updateNavieraSuccess, (state, { naviera }) => (
        navieraAdapter.updateOne(naviera, state)
    )),
    on(navieraActions.updateNavieraError, (state, { payload }) => ({
        ...state,
        error: payload
    })),
    on(navieraActions.deleteNavieraSuccess, (state, { id }) => (
        navieraAdapter.removeOne(id, state)
    )),
    on(navieraActions.deleteNavieraError, (state, { payload }) => ({
        ...state,
        error: payload
    })),

);

export function navieraReducer(state, action) {
    return reducer(state, action);
}